import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ClevertapReact from 'src/utils/clever-tap';
import sharedActions from '@sharedComponent/app-action-reducer/action';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import { focus_state_icons } from '@assets/icons/focus_state';
import ImageComponent from '@sharedComponent/image-component';

const TextContent = ({ handleChange, id, content, index }) => {
	const history = useHistory();
	const dispatch = useDispatch();

	return (
		<div className={`list ${id === index + 1 ? 'list-active' : 'list-hover'}`} onClick={() => handleChange(index + 1)}>
			{id !== index + 1 ? (
				<>
					<ImageComponent src={content.icons} alt={content.alt} className="list-icon" />
					<h5 className="list-text">{content?.title}</h5>
				</>
			) : (
				<>
					<div className="list-active-title">
						<ImageComponent src={content.focusIcons} alt={content.alt} className="list-icon" />
						<h5 className="list-text  list-text-active">{content?.title}</h5>
					</div>
					<div className="line"></div>
					<div className="list-active-sub">{content?.sub}</div>

					<EdenredButtons
						children={content?.buttontext}
						onClick={() => {
							ClevertapReact.event(content?.cleverTapTryNow);
							dispatch(sharedActions.creators.postHrSetupStatus({ hrOnBoardingFlowCompleted: true }));
							history.push(content?.navigate);
						}}
						btnType="primary"
						disabled={false}
						rightIcon={<ImageComponent src={focus_state_icons?.RightArrow} />}
						className="try-now-btn"
					/>
				</>
			)}
		</div>
	);
};

export default TextContent;
