import React, { useEffect, useRef, useState } from 'react';
import { icons } from 'src/assets/icons';
import ContentManager from './ContentManager';
import useOnClickOutside from '@pages/payroll-plus-new/components/useOnClickOutside';
import ETypo from '@pages/DesignSystem/Typo';
import './index.scss';

const CountryDropdownInput = ({
	selectedValue,
	onChange,
	options = [],
	searchHolder = 'Search options',
	searchVisibility = true,
	icon,
	optionValue = '',
	searchLabel = '',
	dropDownSubValue = '',
	value = '',
	checkboxIcon = null,
	showIcon = true,
	placeholder = 'Select',
	isError = false,
	loading = false,
	disabled = false,
	dateFormat = '',
	prefix = '',
	label = '',
	required = false,
	showOptionStatus = false,
	justifyOption = false,
	className = '',
	inputType = 'number',
	country = {},
	inputValue = '',
	onInputChange = () => {},
}) => {
	const ref = useRef(null);
	const inputRef = useRef(null);
	const [dropdownClicked, setDropdownClicked] = useState(false);
	const [inputClicked, setInputClicked] = useState(false);
	const [filter, setFilter] = useState([]);
	const [filterDataClone, setFilterDataClone] = useState([]);
	const [searchKey, setSearchKey] = useState('');
	useOnClickOutside(ref, () => {
		if (dropdownClicked) setDropdownClicked(false);
	});
	useOnClickOutside(inputRef, () => {
		if (inputClicked) setInputClicked(false);
	});

	useEffect(() => {
		const selectId = document.getElementById('selected');
		if (selectId) {
			selectId.scrollIntoView({ behavior: 'smooth', block: 'center' });
		}
	}, [dropdownClicked]);

	useEffect(() => {
		setDropdownData();
	}, [options, value]);

	const setDropdownData = () => {
		const data = Array.isArray(options)
			? options?.map((items, index) => {
					return {
						...items,
						key: index,
						isSelected: items?.[optionValue] === value,
					};
			  })
			: [];
		setFilter(data);
		setFilterDataClone(data);
	};

	const handleDropdown = (data, item) => {
		selectedValue && selectedValue(data);
		const newOptions = filter?.map((value) => {
			return {
				...value,
				isSelected: value?.key === data?.key,
			};
		});
		setFilter(newOptions);
		onChange && onChange(data);
	};

	const handleDropdownClick = (e) => {
		e.stopPropagation();
		setDropdownClicked(!dropdownClicked);
		if (searchKey) {
			setSearchKey('');
			setFilter(filterDataClone);
		}
	};

	const filterSearch = (search) => {
		setSearchKey(search);
		if (search) {
			const filtered = options?.filter((data) => {
				const searchingData = data?.[searchLabel || optionValue]?.toString()?.toLowerCase();
				const searchValue = search?.toString()?.toLowerCase();
				if (searchingData?.includes(searchValue)) {
					return data;
				}
			});
			setFilter(filtered);
		} else {
			setFilter(filterDataClone);
		}
	};

	return (
		<div className="edenred_dropdown_container">
			{label && (
				<div className="edenred_dropdwon_label">
					{label}
					{required && <div style={{ color: '#F72717', marginLeft: '4px' }}>*</div>}
				</div>
			)}
			<div className="edenred_dropdown_container">
				<div
					ref={ref}
					className={`dropdown_inner ${className} ${isError ? 'error-input' : ''} ${disabled ? 'dropdown_disabled' : ''} ${
						dropdownClicked || inputClicked ? 'input_clicked' : ''
					}`}>
					<div className="dropdown-menu" onClick={(e) => !loading && !disabled && handleDropdownClick(e)}>
						{prefix && prefix}
						<div role="button" className="dropdown_arrow">
							<img className="arrow-inner" src={dropdownClicked ? icons.not_focus.up : icons.not_focus.down} alt="dropDown arrow" />
						</div>
						<ETypo medium b2>
							+{country.stdCode}
						</ETypo>
					</div>
					<input
						ref={inputRef}
						placeholder={placeholder}
						type={inputType}
						value={inputValue}
						onClick={() => setInputClicked(true)}
						onChange={(e) => onInputChange(e?.target.value)}
						className="dropdown_placeHolder input_text_box"
					/>
				</div>
				{dropdownClicked ? (
					<div ref={ref} className="dropdown_list" id="dropdown_list" style={{ width: '100%' }}>
						{searchVisibility && (
							<div className="dropdown_list_search_main">
								<img src={icons.not_focus.searchInput} alt="search-input" />
								<input
									placeholder={searchHolder}
									spellCheck={false}
									value={searchKey}
									onChange={(e) => filterSearch(e?.target.value)}
									className="dropdown"
								/>
							</div>
						)}
						<div className="dropdown_inner_list" onClick={(e) => handleDropdownClick(e)}>
							{filter.length ? (
								filter?.map((data) => {
									return (
										<div key={data?.key}>
											<ContentManager
												{...{
													data,
													handleDropdown,
													icon,
													optionValue,
													dropDownSubValue,
													checkboxIcon,
													showIcon,
													dateFormat,
													showOptionStatus,
													justifyOption,
												}}
											/>
										</div>
									);
								})
							) : (
								<p className="no_data_found">No results found</p>
							)}
						</div>
					</div>
				) : null}
			</div>
		</div>
	);
};

export default CountryDropdownInput;
