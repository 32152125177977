import React from 'react';
import propTypes from 'prop-types';
import './tool-tip.scss';

const Tooltip = ({ direction = 'up', children, content, className = '', active = true }) => {
	if (!active) return children;
	return (
		<>
			<div className={`tooltip ${className} `}>
				{children}
				{['up', 'down', 'left', 'right']?.includes(direction) && <span data-testid="test-paid-tooltip-content" className={`tooltip${direction}`}>{content}</span>}
			</div>
		</>
	);
};
Tooltip.propTypes = {
	direction: propTypes.string,
	children: propTypes.object,
	content: propTypes.object,
};
export default Tooltip;
