import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Col, Row } from 'antd';
import { useHistory } from 'react-router-dom';
import * as XLSX from 'xlsx';
import FreshdeskWidgetHelper from '@sharedComponent/freshdesk/FreshdeskWidgetHelper';
import ImageComponent from '@sharedComponent/image-component';
import ETypo from '@pages/DesignSystem/Typo';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import EDragger from '@pages/DesignSystem/Dragger';
import ErrorTable from '@sharedComponent/error-table';
import { templateDownload } from '@helpers';
import EmployeeService from 'src/services/employee-service';
import ClevertapReact from 'src/utils/clever-tap';
import PayrollService from 'src/services/payroll-service';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import { images } from 'src/assets/img';
import { SITEMAP } from 'src/routes/sitemap';

function UploadEmployeesSpreadsheet() {
	const history = useHistory();
	const [uploadDragger, setuploadDragger] = useState(false);
	const [empUploadLoader, setEmpUploadLoader] = useState(false);
	const [validateMessage, setValidateMessage] = useState([]);
	const [, forceUpdate] = useState();
	const [files, setFiles] = useState({});
	const [empData, setempData] = useState([]);
	let employeeApi = new EmployeeService();
	const [counters, setcounters] = useState(0);
	const [errordataList, setErrorDataList] = useState([]);
	const [checkList, setCheckList] = useState([]);
	const [employeeBolbFileName, setemployeeBolbFileName] = useState(null);
	const [isButtonDisabled, setisButtonDisabled] = useState(false);
	const [employeeUploadLoading, setEmployeeUploadLoading] = useState(false);
	const refValue = useRef();

	useEffect(() => {
		forceUpdate({});
	}, [forceUpdate]);
	const disable = useMemo(() => setisButtonDisabled(!!validateMessage?.length || Object.keys(files).length === 0), [validateMessage, files]);
	const _payrollService = new PayrollService();
	const download = () => {
		_payrollService.usefullfileDownload('Employee', 'GET', 'blob').then(({ data }) => {
			templateDownload(new Blob([data], { type: data?.type }), 'employee.xls');
		});
	};
	const onFinish = (values) => {
		const reader = new FileReader();
		reader.onload = (evt) => {
			//for future reference
			// evt = on_file_select event
			/* Parse data */
			// const bstr = evt.target.result;
			// const wb = XLSX.read(bstr, { type: 'binary' });
			// /* Get first worksheet */
			// const wsname = wb.SheetNames[0];
			// const ws = wb.Sheets[wsname];
			// /* Convert array of arrays */

			// const data = XLSX.utils.sheet_to_json(ws, { header: 1, blankRows: false });
			// /* Update state */
			history.push({ pathname: '/employees/add/multiple/employees/submit', state: { data: empData, files } });
		};
		if (files && files.file && files.file.originFileObj) {
			reader.readAsBinaryString(files.file.originFileObj);
		} else {
			history.push({ pathname: '/employees/add/multiple/employees/submit', state: { data: empData, files } });
		}
	};

	const handleUpload = (originFileObj) => {
		setErrorDataList([]);
		let formData = new FormData();
		formData.append('file', originFileObj);
		setEmpUploadLoader(true);
		employeeApi
			.uploadEmployeeData(formData)
			.then((response) => {
				setEmpUploadLoader(false);
				if (response && response?.data && response?.data?.validationMessage) {
					setcounters(counters + 1);
					setemployeeBolbFileName(response?.data?.blobFileName || null);
					setValidateMessage(response?.data?.validationMessage || []);
					setempData([]);
					setisButtonDisabled(true);
					setuploadDragger(true);
				} else {
					setcounters(0);
					setValidateMessage([]);
					setempData(response.data);
					setisButtonDisabled(false);
					setuploadDragger(true);
				}
			})
			.catch((err) => {
				setFiles({});
				setempData([]);
				setEmpUploadLoader(false);
				setisButtonDisabled(true);
			});
	};
	useEffect(() => {
		const FreshdeskWidgetElement = document.getElementById('freshworks-container');
		if (FreshdeskWidgetElement && counters >= 2) {
			var element = document.getElementById('freshworks-container');
			element.classList.add('freshdeskShow');
			element.classList.remove('freshdeskHide');
		}
		return () => {
			const FreshdeskWidgetElement = document.getElementById('freshworks-container');
			if (FreshdeskWidgetElement) {
				var element = document.getElementById('freshworks-container');
				element.classList.add('freshdeskHide');
				element.classList.remove('freshdeskShow');
			}
		};
	}, [counters]);

	if (counters >= 2) {
		FreshdeskWidgetHelper();
	}
	const handleLocalDelete = () => {
		setisButtonDisabled(false);
		setemployeeBolbFileName(null);
		setFiles({});
		setuploadDragger(false);
		setValidateMessage([]);
		setCheckList([]);
	};

	const uploadConfig = (info) => {
		// setEmpUploadLoader(false);
		setemployeeBolbFileName(null);
		setFiles(info);
		handleUpload(info);
		setCheckList([]);
		setuploadDragger(false);
	};
	// future reference
	// async function fetchEmployees(
	// 	queryParams = {
	// 		status: 'ACTIVE',
	// 		includeEmployeeCounts: true,
	// 	}
	// ) {
	// 	try {
	// 		let response = await employeeApi.getEmployees(queryParams);
	// 		if (response && response.data) {
	// 			SetemployeeTableData([...response.data.employees]);
	// 		}
	// 	} catch (err) {
	// 		SetemployeeTableData([]);
	// 	}
	// }

	// future reference
	// async function fetchEmployees(
	// 	queryParams = {
	// 		status: 'ACTIVE',
	// 		includeEmployeeCounts: true,
	// 	}
	// ) {
	// 	try {
	// 		let response = await employeeApi.getEmployees(queryParams);
	// 		if (response && response.data) {
	// 			SetemployeeTableData([...response.data.employees]);
	// 		}
	// 	} catch (err) {
	// 		SetemployeeTableData([]);
	// 	}
	// }

	// useEffect(() => {
	// 	fetchEmployees();
	// }, []);

	async function onNextClick() {
		ClevertapReact.event('Next_BulkEmployees');
		let errorDataPayLoad = {
			employeeBlobFileName: employeeBolbFileName,
			employeeFileAutoFixModel: errordataList,
		};
		setEmployeeUploadLoading(true);
		if (errordataList?.length !== 0) {
			try {
				let response = await employeeApi.validateUploadEmployeeSpreadSheet(errorDataPayLoad);
				if (response && response?.data && response?.data?.validationMessage) {
					setcounters(counters + 1);
					setValidateMessage(response?.data?.validationMessage || []);
					setempData([]);
					setuploadDragger(true);
					setCheckList([]);
					setEmployeeUploadLoading(false);
				} else {
					setEmployeeUploadLoading(false);
					setcounters(0);
					setValidateMessage([]);

					const reader = new FileReader();
					reader.onload = (evt) => {
						history.push({ pathname: '/employees/add/multiple/employees/submit', state: { data: response?.data, files } });
						// evt = on_file_select event
						/* Parse data */
						const bstr = evt.target.result;
						const wb = XLSX.read(bstr, { type: 'binary' });
						// /* Get first worksheet */
						const wsname = wb.SheetNames[0];
						const ws = wb.Sheets[wsname];
						// /* Convert array of arrays */
						XLSX.utils.sheet_to_json(ws, { header: 1, blankRows: false });
						// /* Update state */
					};

					if (files?.file && files?.file?.originFileObj) {
						reader.readAsBinaryString(files?.file?.originFileObj);
					} else {
						history.push({ pathname: '/employees/add/multiple/employees/submit', state: { files } });
					}
				}
			} catch (error) {
				setEmployeeUploadLoading(false);
			}
		} else {
			if (validateMessage.length === 0) {
				onFinish();
			}
			setEmployeeUploadLoading(false);
		}
	}

	const draggerIcon = () => {
		if (empUploadLoader) {
			return focus_state_icons.hrUploading;
		} else if (validateMessage.length > 0) {
			return focus_state_icons.empEssential;
		} else if (uploadDragger) {
			return focus_state_icons.NewDesignCheck;
		} else {
			return focus_state_icons.hrUpload;
		}
	};
	const draggerStatus = () => {
		if (empUploadLoader) {
			return 'uploading';
		} else if (validateMessage.length > 0) {
			return 'done';
		} else if (uploadDragger) {
			return 'done';
		} else {
			return 'new';
		}
	};
	const handleErrTatble = (res) => setErrorDataList(res);
	const enablebutton = (res) => setisButtonDisabled(!res);
	return (
		<Col className="upload-speadsheet-col-content">
			<Row className="upload_employees_spreadsheet_container">
				<Col className="upload_employees_spreadsheet_title">
					<div className="add-employee-header">
						<ETypo h2 bold color="#00344E">
							Add Employee
						</ETypo>
					</div>
					<div className="add-employee-button-container">
						<EdenredButtons
							style={{ padding: '8px 16px 8px 10px', width: '85px' }}
							btnType={'secondary'}
							onClick={() => history.push(SITEMAP.employees.add)}>
							<ImageComponent src={images.leftsarrow}></ImageComponent>{' '}
							<ETypo style={{ marginLeft: '3px' }} b1 bold color="#484B52">
								Back
							</ETypo>
						</EdenredButtons>
						<EdenredButtons
							style={{ marginLeft: '8px', width: '65px' }}
							onClick={onNextClick}
							loading={employeeUploadLoading}
							disabled={isButtonDisabled}>
							Next
						</EdenredButtons>
					</div>
				</Col>

				<Col className="upload-box-and-table-container">
					<ETypo h4 color="#00344E">
						Import Employee Spreadsheet
					</ETypo>
					<div className="add-employee-dragger-container">
						<EDragger
							{...{
								idleIcon: draggerIcon(),
								draggerStatus: draggerStatus(),
								uploadDeleteIcon: focus_state_icons.NewDelete,
								acceptedTypes: '.xls, .xlsx',
								onChange: uploadConfig,
								onRemoveUpload: handleLocalDelete,
								refValue,
							}}
						/>
					</div>
					{!uploadDragger && (
						<div className="add-employee-download-spreadsheet">
							<ETypo className="whole-click-here-text-style" light b1 color="#181919">
								If you don’t have the spreadsheet template you can download it
								<ETypo className="Here-text" light b1 onClick={download} style={{ cursor: 'pointer' }}>
									HERE.
								</ETypo>
							</ETypo>
						</div>
					)}
					{validateMessage?.length > 0 && (
						<>
							<div className="warning-message-container">
								<ImageComponent style={{ marginLeft: '8px' }} src={focus_state_icons.empUploadErrorMessage}></ImageComponent>
								<ETypo style={{ width: '73%' }} className="errormessage-text-style" b1 light color="##00344E">
									Please correct the following errors and upload the file again. You can continue with these employees excluded or
									correct the file and upload it again,
								</ETypo>
								{/* <input type="file" id="filesystem" accept=".xls, .xlsx"> */}
								<ETypo
									onClick={() => refValue.current.click()}
									style={{ display: 'flex', paddingLeft: '32px', width: '19%', cursor: 'pointer', textDecoration: 'underline' }}
									b2
									color="#0E5274"
									medium
									className="Here-text">
									Reupload Spreadsheet
								</ETypo>
							</div>
							<div style={{ display: 'flex', flexDirection: 'row', width: '100%' }} className="error_table_emp">
								<ErrorTable
									data={validateMessage && validateMessage}
									loadErrorData={handleErrTatble}
									enableNextButton={enablebutton}
								/>
							</div>
						</>
					)}
				</Col>
			</Row>
		</Col>
	);
}

export default UploadEmployeesSpreadsheet;
