import React from 'react';
import { icons } from '@assets/icons/index';
import EdenredNewLable from '@sharedComponent/Lable/newLable';
import { images } from 'src/assets/img';
import { ALL_EMPLOYEE_CLAIMS, ALL_HR_CLAIMS, ALL_PAYROLL_CLAIMS, DEFAULT_SHOW, PERMISSIONS, SHOW_ONLY_IN_DEV } from '@constants/app-constants';
import { checkHrSubscription } from './../../../../helpers/index';
import { APP_VARIABLES } from './../../../../constants/app-constants';

// payroll + hr =! Home
// Payoll + !hr =! Home
// !payrool + hr = Home

export const allMenus = [
	{
		key: 'features',
		label: 'New Features!',
		icon: icons.not_focus.newFeature,
		activeIcon: icons.focus_state.newFeature,
		path: '/new-hr-features/summary',
		isVisible: false,
		roles: 'Payroll',
		isBottomNav: false,
		isSubscribed: false,
		toShow: [
			PERMISSIONS.MANAGE_EMPLOYEES,
			PERMISSIONS.MANAGE_PAYROLL,
			PERMISSIONS.APPROVE_PAYROLL_REQUEST,
			PERMISSIONS.APPROVE_CARD_CANCELLATION,
			PERMISSIONS.APPROVE_CARD_ORDERS,
			PERMISSIONS.MANAGE_LEAVES,
			PERMISSIONS.MANAGE_DOCUMENTS,
			PERMISSIONS.MANAGE_ANNOUNCEMENTS,
			PERMISSIONS.SETUP_LEAVE_APPROVAL_WORKFLOW_AND_EDIT_LEAVE_BALANCES,
			PERMISSIONS.APPROVE_CARD_REPLACEMENT,
		],
		forSubscribedUsersOnly: true,
		show: [],
		subMenus: [],
		isSelect: false,
		isnavigation: 'payroll',
		chip: true,
	},
	{
		key: 'dashboard',
		label: 'Dashboard',
		icon: icons.not_focus.Gridnew,
		activeIcon: icons.focus_state.Gridnew,
		path: '/',
		isVisible: false,
		roles: 'Payroll',
		isBottomNav: false,
		isSubscribed: false,
		toShow: [
			PERMISSIONS.MANAGE_EMPLOYEES,
			PERMISSIONS.MANAGE_PAYROLL,
			PERMISSIONS.APPROVE_PAYROLL_REQUEST,
			PERMISSIONS.APPROVE_CARD_REPLACEMENT,
			PERMISSIONS.APPROVE_CARD_CANCELLATION,
			PERMISSIONS.APPROVE_CARD_ORDERS,
		],
		show: [],
		subMenus: [],
		isSelect: false,
		isnavigation: 'payroll',
	},
	{
		label: 'Home',
		key: 'home',
		icon: icons.not_focus.Homebank,
		activeIcon: icons.not_focus.Homebank,
		path: '/home',
		isVisible: false,
		roles: 'Employee',
		isBottomNav: false,
		isSubscribed: false,
		toShow: [
			PERMISSIONS.MANAGE_LEAVES,
			PERMISSIONS.MANAGE_DOCUMENTS,
			PERMISSIONS.MANAGE_ANNOUNCEMENTS,
			PERMISSIONS.SETUP_LEAVE_APPROVAL_WORKFLOW_AND_EDIT_LEAVE_BALANCES,
			...ALL_EMPLOYEE_CLAIMS,
		],
		notToShow: [...ALL_PAYROLL_CLAIMS],
		subMenus: [],
		isSelect: false,
		isnavigation: 'hr',
	},
	{
		label: 'Request Leave',
		key: 'request-leave',
		icon: icons.not_focus.leavenew,
		activeIcon: icons.not_focus.leavenew,
		path: '/employee/request_leave',
		isVisible: false,
		roles: 'Employee',
		isBottomNav: false,
		isSubscribed: false,
		toShow: [...ALL_EMPLOYEE_CLAIMS],
		notToShow: [...ALL_HR_CLAIMS],
		subMenus: [],
		isSelect: false,
		isnavigation: 'hr',
	},
	{
		key: 'payroll',
		label: 'Payroll',
		icon: icons.not_focus.payrollnew1,
		activeIcon: icons.not_focus.payrollnew,
		claimValue: 'Manage.Payroll',
		cleverTapValue: 'Payroll',
		// path: '/run-payroll',
		path: (availableSubMenus) => {
			const checkSubscription = checkHrSubscription(JSON.parse(localStorage.getItem(APP_VARIABLES.PROD_SUBSCRIPTIONS)));
			let result = '/';
			if (Array.isArray(availableSubMenus) && availableSubMenus.length > 0) {
				if (availableSubMenus[0]?.key === 'run-payroll') {
					result = checkSubscription === true ? '/payroll-plus' : '/run-payroll';
				} else {
					result = availableSubMenus[0]?.path || '/';
				}
			} else {
				result = '/';
			}
			return result;
		},
		secondaryPath: false,
		isVisible: false,
		roles: 'Payroll',
		isBottomNav: false,
		isSubscribed: false,
		toShow: [PERMISSIONS.MANAGE_PAYROLL, PERMISSIONS.APPROVE_PAYROLL_REQUEST],
		subMenus: [
			{
				label: 'Run Payroll',
				key: 'run-payroll',
				// path: '/run-payroll',
				path: () => {
					const checkSubscription = checkHrSubscription(JSON.parse(localStorage.getItem(APP_VARIABLES.PROD_SUBSCRIPTIONS)));
					let result = checkSubscription === true ? '/payroll-plus' : '/run-payroll';
					return result;
				},
				secondaryPath: false,
				roles: 'Payroll',
				cleverTapValue: 'RunPayroll',
				toShow: [PERMISSIONS.MANAGE_PAYROLL],
				isSelect: false,
			},
			{
				label: 'Payroll Status',
				key: 'payroll-status',
				path: '/payroll/history',
				roles: 'Payroll',
				cleverTapValue: 'PayrollStatus',
				toShow: [PERMISSIONS.MANAGE_PAYROLL, PERMISSIONS.APPROVE_PAYROLL_REQUEST],
				isSelect: false,
			},
			{
				label: 'Proof of Funds Transfer',
				key: 'proof-of-funds-transfer',
				path: '/payroll/proof_of_transfer',
				roles: 'Payroll',
				cleverTapValue: 'ProofofTransferFunds',
				toShow: [PERMISSIONS.MANAGE_PAYROLL, PERMISSIONS.APPROVE_PAYROLL_REQUEST],
				isSelect: false,
			},
			{
				label: 'Approve Requests',
				key: 'payroll-approve-requests',
				path: '/payroll/approve_requests',
				roles: 'Payroll',
				cleverTapValue: 'ApproveRequests_Payroll',
				toShow: [PERMISSIONS.APPROVE_PAYROLL_REQUEST, PERMISSIONS.APPROVE_PAYROLL_REQUEST],
				isSelect: false,
			},
			{
				label: 'Payroll Timings',
				key: 'payroll-timings',
				path: '/payroll/timings',
				roles: 'Payroll',
				cleverTapValue: 'PayrollTimings',
				toShow: [PERMISSIONS.MANAGE_PAYROLL, PERMISSIONS.APPROVE_PAYROLL_REQUEST],
				isSelect: false,
			},
			{
				label: 'Our Bank Details',
				key: 'our-bank-details',
				path: '/payroll/bank_details',
				roles: 'Payroll',
				cleverTapValue: 'OurBankDetails',
				toShow: [PERMISSIONS.MANAGE_PAYROLL, PERMISSIONS.APPROVE_PAYROLL_REQUEST],
				isSelect: false,
			},
			{
				label: 'Salary Reports',
				key: 'salary-reports',
				path: '/salary',
				roles: 'Payroll',
				cleverTapValue: 'salaryreports',
				toShow: [PERMISSIONS.MANAGE_PAYROLL, PERMISSIONS.APPROVE_PAYROLL_REQUEST],
				isSelect: false,
			},
			//future ref
			// {
			// 	label: 'Useful Files',
			// 	key: 'payroll-useful-files',
			// 	path: '/payroll/useful_files',
			// 	roles: 'Payroll',
			// 	cleverTapValue: 'UsefulFiles_Payroll',
			// 	toShow: [PERMISSIONS.MANAGE_PAYROLL, PERMISSIONS.APPROVE_PAYROLL_REQUEST],
			// 	isSelect: false,
			// },
		],
		isSelect: false,
		isnavigation: 'payroll',
	},
	{
		key: 'employees',
		label: 'Employees',
		icon: icons.not_focus.Usersnew,
		activeIcon: icons.focus_state.Usersnew,
		claimValue: 'Manage.Employees',
		// path: '/employees/manage_employee',
		path: (availableSubMenus) => {
			if (Array.isArray(availableSubMenus) && availableSubMenus.length > 0) {
				return availableSubMenus[0]?.path || '/';
			}
			return '/employees/manage_employee';
		},
		isVisible: false,
		roles: 'Payroll',
		isBottomNav: false,
		isSubscribed: false,
		cleverTapValue: 'ManageEmployees',
		toShow: [
			PERMISSIONS.MANAGE_EMPLOYEES,
			PERMISSIONS.APPROVE_CARD_ORDERS,
			PERMISSIONS.APPROVE_CARD_REPLACEMENT,
			PERMISSIONS.APPROVE_CARD_CANCELLATION,
		],
		subMenus: [
			{
				label: 'Manage Employees',
				key: 'manage-employees',
				path: '/employees/manage_employee',
				roles: 'Payroll',
				isBottomNav: false,
				cleverTapValue: 'ManageEmployees',
				toShow: [
					PERMISSIONS.MANAGE_EMPLOYEES,
					PERMISSIONS.APPROVE_CARD_ORDERS,
					PERMISSIONS.APPROVE_CARD_REPLACEMENT,
					PERMISSIONS.APPROVE_CARD_CANCELLATION,
				],
				isSelect: false,
			},
			{
				label: 'Card Order Status',
				key: 'card-order-status',
				path: '/employees/card/order/status',
				roles: 'Payroll',
				isBottomNav: false,
				cleverTapValue: 'CardOrderStatus',
				toShow: [
					PERMISSIONS.MANAGE_EMPLOYEES,
					PERMISSIONS.APPROVE_CARD_ORDERS,
					PERMISSIONS.APPROVE_CARD_REPLACEMENT,
					PERMISSIONS.APPROVE_CARD_CANCELLATION,
				],
				isSelect: false,
			},
			{
				label: 'Approve Requests',
				key: 'employees-approve-requests',
				path: '/employees/approve_employee_request',
				roles: 'Payroll',
				isBottomNav: false,
				cleverTapValue: 'ApproveRequests_Employees',
				toShow: [PERMISSIONS.APPROVE_CARD_ORDERS, PERMISSIONS.APPROVE_CARD_REPLACEMENT, PERMISSIONS.APPROVE_CARD_CANCELLATION],
				isSelect: false,
			},
			//future reference
			// {
			// 	label: 'Useful Files',
			// 	key: 'employees-useful-files',
			// 	path: '/employees/useful/files',
			// 	roles: 'Payroll',
			// 	isBottomNav: false,
			// 	cleverTapValue: 'UsefulFiles_Payroll',
			// 	toShow: [PERMISSIONS.MANAGE_EMPLOYEES],
			// 	isSelect: false,
			// },
		],
		isSelect: false,
		isnavigation: 'payroll',
	},
	{
		key: 'leaves',
		label: 'Leaves',
		icon: icons.not_focus.leavenew,
		activeIcon: icons.focus_state.leavenew,
		claimValue: 'Manage.Leaves',
		newIcon: <EdenredNewLable />,
		// path: '/leaves/leaves_to_approve',
		path: (availableSubMenus) => {
			if (Array.isArray(availableSubMenus) && availableSubMenus.length > 0) {
				return availableSubMenus[0]?.path || '/';
			}
			return '/leaves/leaves_to_approve';
		},
		isVisible: false,
		roles: 'HR',
		isBottomNav: false,
		isSubscribed: false,
		cleverTapValue: 'LeavesToApprove_navbar',
		toShow: [PERMISSIONS.MANAGE_LEAVES, PERMISSIONS.SETUP_LEAVE_APPROVAL_WORKFLOW_AND_EDIT_LEAVE_BALANCES],
		forSubscribedUsersOnly: true,
		chip: true,
		subMenus: [
			{
				label: 'Leaves to Approve',
				key: 'leavestoapprove',
				path: '/leaves/leaves_to_approve',
				roles: 'HR',
				isBottomNav: false,
				cleverTapValue: '',
				toShow: [PERMISSIONS.MANAGE_LEAVES],
				isSelect: false,
			},
			{
				label: 'Leave Statuses',
				key: 'leavestatues',
				path: '/leaves/leaves_statuses',
				roles: 'HR',
				isBottomNav: false,
				cleverTapValue: 'Leave_leavestatuses_navbar',
				toShow: [PERMISSIONS.MANAGE_LEAVES],
				isSelect: false,
			},
			{
				label: 'Request Leave',
				key: 'requestleave',
				path: '/leaves/request_leave',
				roles: 'HR',
				isBottomNav: false,
				cleverTapValue: 'Leave_requestleave_navbar',
				toShow: [PERMISSIONS.MANAGE_LEAVES],
				isSelect: false,
			},
			{
				label: 'Approval Workflow',
				key: 'approvalworkflow',
				path: '/approval-workflow-leave',
				roles: 'HR',
				isBottomNav: false,
				cleverTapValue: 'Leave_approvalworkflow_navbar',
				toShow: [PERMISSIONS.SETUP_LEAVE_APPROVAL_WORKFLOW_AND_EDIT_LEAVE_BALANCES],
				isSelect: false,
			},
		],
		isSelect: false,
		isnavigation: 'hr',
	},

	{
		label: 'Documents',
		key: 'documents',
		icon: icons.not_focus.docnew,
		activeIcon: icons.focus_state.docnew,
		claimValue: 'Manage.Documents',
		path: '/hrdocuments',
		newIcon: <EdenredNewLable />,
		roles: 'HR',
		isBottomNav: false,
		isVisible: false,
		isSubscribed: false,
		cleverTapValue: 'hrdocuments',
		toShow: [PERMISSIONS.MANAGE_DOCUMENTS],
		subMenus: [],
		forSubscribedUsersOnly: true,
		isSelect: false,
		isnavigation: 'hr',
		chip: true,
	},
	{
		label: 'Payslip',
		key: 'payslip',
		icon: icons.not_focus.Receiptbank,
		activeIcon: icons.not_focus.Receiptbank,
		claimValue: 'View.Payslip',
		path: '/payslip',
		roles: 'Employee',
		isBottomNav: false,
		isVisible: false,
		isSubscribed: false,
		cleverTapValue: 'payslip',
		toShow: [...ALL_EMPLOYEE_CLAIMS],
		subMenus: [],
		isSelect: false,
		isnavigation: 'hr',
	},
	// {
	// 	label: 'Design System',
	// 	key: 'design-system',
	// 	icon: icons.not_focus.payslipIcon,
	// 	activeIcon: icons.not_focus.payslipIcon,
	// 	claimValue: 'View.Payslip',
	// 	path: '/design-system',
	// 	roles: 'Employee',
	// 	isBottomNav: false,
	// 	isVisible: true,
	// 	isSubscribed: false,
	// 	cleverTapValue: 'designsystem',
	// 	toShow: [SHOW_ONLY_IN_DEV],
	// 	subMenus: [],
	// 	isSelect: false,
	// 	isnavigation: 'default',
	// },
	{
		label: 'Announcements',
		key: 'announcements',
		icon: icons.not_focus.announcenew,
		activeIcon: icons.focus_state.announcenew,
		claimValue: 'Manage.Announcements',
		path: '/new-hr-features/announcement',
		newIcon: <EdenredNewLable />,
		roles: 'HR',
		isBottomNav: false,
		isVisible: false,
		isSubscribed: false,
		cleverTapValue: 'ann_navbar',
		toShow: [PERMISSIONS.MANAGE_ANNOUNCEMENTS, ...ALL_EMPLOYEE_CLAIMS],
		subMenus: [],
		forSubscribedUsersOnly: true,
		isSelect: false,
		isnavigation: 'hr',
		chip: true,
	},
	{
		label: 'Company',
		key: 'company',
		icon: icons.not_focus.Buildingnew,
		activeIcon: icons.focus_state.Buildingnew,
		path: (availableSubMenus) => {
			if (Array.isArray(availableSubMenus) && availableSubMenus.length > 0) {
				return availableSubMenus[0]?.path || '/';
			}
			return '/company/company-setting';
		},
		isVisible: false,
		roles: 'Payroll',
		isBottomNav: false,
		isSubscribed: false,
		cleverTapValue: 'Company',
		toShow: [PERMISSIONS.MANAGE_ROLES_AND_PERMISSIONS, ...ALL_HR_CLAIMS, ...ALL_PAYROLL_CLAIMS],
		subMenus: [
			{
				label: 'Company Settings',
				key: 'company-setting',
				path: '/company/company-setting',
				roles: 'Payroll',
				isBottomNav: false,
				cleverTapValue: 'Company',
				toShow: [...ALL_PAYROLL_CLAIMS],
				forSubscribedUsersOnly: true,
				isSelect: false,
			},
			{
				label: 'Team Permissions',
				key: 'team-permissions',
				path: '/manage_team',
				roles: 'RolesPermissions',
				isBottomNav: false,
				cleverTapValue: 'TeamPermissions_NaviBar',
				toShow: [PERMISSIONS.MANAGE_ROLES_AND_PERMISSIONS],
				isSelect: false,
			},
			{
				label: 'Company Information',
				key: 'company-information',
				path: '/company/information',
				roles: 'Payroll',
				isBottomNav: false,
				cleverTapValue: 'Company infromation',
				toShow: [...ALL_HR_CLAIMS, ...ALL_PAYROLL_CLAIMS],
				isSelect: false,
			},
		],
		isSelect: false,
		isnavigation: 'default',
	},
	{
		label: 'Unemployment Insurance',
		key: 'un-employment-insurance',
		icon: icons.not_focus.security_1_inactive,
		activeIcon: icons.not_focus.security_1_active,
		path: '/un-employment-insurance/employee',
		isBottomNav: false,
		isVisible: false,
		isSubscribed: false,
		roles: 'Payroll',
		toShow: [DEFAULT_SHOW],
		subMenus: [],
		isSelect: false,
		isnavigation: 'default',
	},
	{
		type: 'common',
		key: 'know-more',
		path: '/landingpage',
		element: `<div className="experience"><p className="experience-content">Experience better HR and Payroll</p><button>Know more </button></div>`,
		isVisible: false,
		isBottomNav: true,
		isSelect: false,
		isnavigation: 'default',
		toShow: [DEFAULT_SHOW],
		subMenus: [],
	},

	/**
	 * As per Uzair's request we hiding Health Insurance menu
	 */
	// {
	// 	label: 'Health Insurance',
	// 	key: 'health-insurance',
	// 	icon: icons.not_focus.heartnew,
	// 	activeIcon: icons.focus_state.heartnew,
	// 	path: '/healthinsurance',
	// 	isVisible: true,
	// 	roles: 'Payroll',
	// 	isBottomNav: false,
	// 	isSubscribed: false,
	// 	cleverTapValue: 'HealthInsurance',
	// 	toShow: [...ALL_HR_CLAIMS, ...ALL_PAYROLL_CLAIMS],
	// 	subMenus: [],
	// 	isSelect: false,
	// 	isnavigation: 'default',
	// },
	{
		label: 'Invoice',
		key: 'invoice',
		icon: icons.not_focus.invoice,
		activeIcon: icons.focus_state.InvoiceFocus,
		path: '/proof-of-invoice',
		isBottomNav: true,
		isVisible: false,
		isSubscribed: false,
		roles: 'Payroll',
		toShow: [...ALL_HR_CLAIMS, ...ALL_PAYROLL_CLAIMS],
		subMenus: [],
		isSelect: false,
		isnavigation: 'bottom',
	},
	{
		label: 'Useful Files',
		key: 'usefulFiles',
		icon: icons.not_focus.folderheart,
		activeIcon: icons.focus_state.folderheart,
		path: '/employees/useful/files',
		isBottomNav: true,
		isVisible: false,
		isSubscribed: false,
		roles: 'Payroll',
		toShow: [...ALL_HR_CLAIMS, ...ALL_PAYROLL_CLAIMS],
		subMenus: [],
		isSelect: false,
		isnavigation: 'bottom',
	},
	{
		label: 'Video Tutorials',
		key: 'video-tutorials',
		icon: icons.not_focus.folderheart,
		activeIcon: icons.focus_state.folderheart,
		path: '/videoTutorial',
		isBottomNav: true,
		isVisible: false,
		isSubscribed: false,
		roles: 'Payroll',
		toShow: [...ALL_HR_CLAIMS, ...ALL_PAYROLL_CLAIMS],
		subMenus: [],
		isSelect: false,
		isnavigation: 'bottom',
	},
	{
		label: 'Help',
		key: 'help',
		icon: icons.not_focus.helpnew,
		activeIcon: icons.focus_state.helpnew,
		path: '/help',
		isBottomNav: true,
		isVisible: false,
		isSubscribed: false,
		roles: 'Payroll',
		toShow: [...ALL_HR_CLAIMS, ...ALL_PAYROLL_CLAIMS],
		subMenus: [],
		isSelect: false,
		isnavigation: 'bottom',
	},
	{
		label: 'Logout',
		key: 'logout',
		icon: icons.not_focus.logoutnew,
		activeIcon: icons.not_focus.SwitchOff,
		path: '/leads',
		isBottomNav: true,
		isVisible: false,
		isSubscribed: false,
		roles: 'Payroll',
		toShow: [DEFAULT_SHOW],
		subMenus: [],
		isSelect: false,
		isnavigation: 'bottom',
	},

	// leaves - forSubscribedUsersOnly : true,
	// documents - forSubscribedUsersOnly : true,
	// announcements - forSubscribedUsersOnly : true,
];
export const USER_HANDLE_PERMISSION_TYPES = {
	MENUS: 'MENUS',
	SCREENS: 'SCREENS',
};
