import Axios from 'axios';
import { DOMAIN_URL } from '@constants/app-constants';

class UserService {
    baseUrl = `${DOMAIN_URL}`;
    getAllCountries() {
        return Axios.get(`${this.baseUrl}/Users/countries`);
    }
}

export default UserService;
