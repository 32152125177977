import action from './action';
import ClevertapReact from 'src/utils/clever-tap';
import Edenredmodal from '../../DesignSystem/Edenredmodal/index';
import HeroContent from '../Component/HeroContent/HeroContent';
import React, { useEffect, useState } from 'react';
import SetupTitle from '../Component/SetupTitle/SetupTitle';
import Stepper from '../Component/Stepper/Stepper';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import hrFeatureAction from '../HrFeatureAccess/action';
import { icons } from 'src/assets/icons';
import '../allowance/index.scss';

const Addition = ({ cleverTap = false }) => {
	const initialAdditionList = [
		{
			name: 'Bonus',
			isActive: false,
			id: '',
		},
		{
			name: 'Commission',
			isActive: false,
			id: '',
		},
		{
			name: 'Leave Settlement',
			isActive: false,
			id: '',
		},
		{
			name: 'Annual Ticket',
			isActive: false,
			id: '',
		},
		{
			name: 'Expense Claim',
			isActive: false,
			id: '',
		},
	];
	const userName = useSelector((state) => state?.sharedStates?.currentUser?.user?.userName);
	const location = useLocation();
	const stepperItems = location?.state?.editUploadSpreadsheet
		? [
				{
					name: '1 - Allowance',
					isActive: false,
					isCompleted: true,
					navigate: '/hr-features/allowance',
					editUploadSpreadsheet: true,
				},
				{
					name: '2 - Additions',
					isActive: true,
					isCompleted: false,
					navigate: '/hr-features/addition',
					editUploadSpreadsheet: true,
				},
				{
					name: '3 - Deductions',
					isActive: false,
					isCompleted: false,
					navigate: '/hr-features/deduction',
					editUploadSpreadsheet: true,
				},
		  ]
		: [
				{
					name: '1 - Allowance',
					isActive: false,
					isCompleted: true,
					navigate: '/new-hr-features/allowance',
				},
				{
					name: '2 - Additions',
					isActive: true,
					isCompleted: false,
					navigate: '/new-hr-features/addition',
				},
				{
					name: '3 - Deductions',
					isActive: false,
					isCompleted: false,
					navigate: '/new-hr-features/deduction',
				},
				{
					name: '4 - Upload Data (Optional)',
					isActive: false,
					isCompleted: false,
					navigate: '/new-hr-features/uploadPayrollDetails',
				},
		  ];
	// const storeData = useSelector((state) => state?.hrSetup?.addition?.data);
	const storeData = useSelector((state) => state?.hrSetup?.corporateSettings?.additionTypes);
	// const convertedList = storeData?.map((item) => ({ id: item?.id, name: item.name, isActive: true })); // adding checked property to the list(checkbox status)
	let mergeList = [];
	if (storeData) {
		const uniqueList = initialAdditionList.filter((item) => !storeData.some((element) => element.name === item.name)); // removing same categories
		mergeList = [...uniqueList, ...storeData]; // merging initial list with backend data
	} else {
		mergeList = [...initialAdditionList];
	}
	const [modalOpen, setModalOpen] = useState({ state: false, value: '' });
	const [additionList, setAdditionList] = useState([...mergeList]);
	const [newAdditionItem, setNewAdditionItem] = useState('');
	const [postList, setPostList] = useState([]);
	const [showModal, setShowModal] = useState('');
	const dispatch = useDispatch();
	const history = useHistory();
	const { addition } = useSelector((state) => state?.hrSetup) || [];
	const [isAlreadyExists, setIsAlreadyExists] = useState(false);

	const handleCheckboxState = (isSelected, value, isActive, isEmployeeExists) => {
		ClevertapReact.event(!cleverTap ? 'V1_1_additions_AddNew' : 'V1_1_UploadSpreadEdit_additions_AddNew');
		if (isActive === true && isEmployeeExists === true) {
			setModalOpen({ state: true, value: modalOpen?.value });
		} else {
			// changing checkbox state
			const additionListCopy = [...additionList]; // storing addition list
			const indexOfValue = additionListCopy.findIndex((item) => item.name === value); // finding index of the element
			if (indexOfValue > -1) {
				additionListCopy[indexOfValue].isActive = isSelected;
				setAdditionList(additionListCopy);
			}
		}
	};
	const addNewAddition = (value = '') => {
		ClevertapReact.event('V1_1_additions_AddNew');
		// add new category to the addition list
		setShowModal('AddNewAddition');
		const isValueNewItem = !additionList.some((item) => item.name == value); // checking value is unique or not

		if (value !== '' && isValueNewItem) {
			setAdditionList((prev) => [...prev, { id: '', name: value.trim(), isActive: true }]);
			setNewAdditionItem('');
			setShowModal('');
		}
	};
	const closeModal = () => {
		setShowModal('');
		setNewAdditionItem('');
		setIsAlreadyExists(false);
	};

	const saveChanges = () => {
		const filteredAdditionList = [...additionList].filter((item) => item?.isActive === true); // storing checked item to the new variable
		const isEdited = JSON.stringify(storeData.filter((item) => item?.isActive === true)) !== JSON.stringify(filteredAdditionList); // comparing backend data with user selected list
		const finalList = filteredAdditionList.map((item) => ({ name: item?.name, id: item?.id || '' })); // converting list to match backend list format
		setPostList([...finalList]);
		if (finalList && finalList.length > 0) {
			const cleverTapData = finalList.map((data) => data.name);
			const cleverTapProperties = {
				AdditionTypes: cleverTapData.toString(),
			};
			ClevertapReact.event(
				!cleverTap ? 'V1_1_additions_Next' : 'V1_1_UploadSpreadEdit_additions_Next',
				cleverTapData.length > 0 ? cleverTapProperties : null
			);
			if (isEdited) {
				dispatch(action.creators.postAddition(finalList));
			}
			history.push(
				location?.state?.editUploadSpreadsheet
					? { pathname: '/hr-features/deduction', state: { editUploadSpreadsheet: true } }
					: '/new-hr-features/deduction'
			);
		} else {
			setShowModal('SkipOrSaveChanges');
		}
	};
	const onSkipForNow = () => {
		ClevertapReact.event(!cleverTap ? 'V1_1_additions_PopSkip' : 'V1_1_UploadSpreadEdit_additions_PopSkip');
		dispatch(action.creators.postAddition(postList));
		history.push(
			location?.state?.editUploadSpreadsheet
				? { pathname: '/hr-features/deduction', state: { editUploadSpreadsheet: true } }
				: '/new-hr-features/deduction'
		);
	};

	const handleChange = (event) => {
		const value = event?.target?.value;
		setNewAdditionItem(value);
		let isUnique = additionList.some((item) => item?.name.toLowerCase() === value.trim().toLowerCase());
		if (isUnique === false) {
			setIsAlreadyExists(false);
		} else {
			setIsAlreadyExists(true);
		}
	};

	useEffect(() => {
		dispatch(hrFeatureAction.creators.getCorporateSettings());
		dispatch(action.creators.getAddition());
	}, []);

	return (
		<>
			<main className="main-wrapper">
				<div className="container_title_box">
					<SetupTitle
						title="What type of ADDITIONS do you have?"
						rightBtnClick={saveChanges}
						rightBtnRightIcon={focus_state_icons.RightArrow}
						rightBtnText="Next"
						rightBtnType="primary"
					/>
				</div>
				<div className="hero-content">
					<Stepper
						listOfTabs={stepperItems}
						// handleTabClick={} (under discussion)
					/>
					<HeroContent
						title="Additions are a variable pay type that is included in the total salary. Please select the types of additions you include in your employees' salaries during the payroll run."
						list={additionList}
						handleCheckboxState={handleCheckboxState}
						onAddNewItem={() => addNewAddition(newAdditionItem)}
						addNewItemButtonTitle={'Add New Additions'}
						loader={addition?.loading}
					/>
				</div>
				{modalOpen?.state && addition?.loading === false && (
					<Edenredmodal
						title="Warning"
						desc={`You cannot unselect ${modalOpen?.value} because payroll has been processed with this addition`}
						onOkay={() => setModalOpen({ state: false, value: '' })}
						onOkayButton="Okay"
						isOpen={modalOpen?.state}
						imageSrc={icons.not_focus?.info2}
						heart={true}
					/>
				)}
			</main>
			{showModal === 'SkipOrSaveChanges' && (
				<Edenredmodal
					title="You didn’t add new Addition"
					desc="Adding addition types will help you to better track and manage employee pay."
					onOkay={() => {
						ClevertapReact.event(!cleverTap ? 'V1_1_additions_PopAdd' : 'V1_1_UploadSpreadEdit_additions_PopAdd');
						setShowModal('');
					}}
					onCancelButton="Skip For Now"
					onOkayButton="Add Addition"
					onCancel={onSkipForNow}
					isOpen={showModal !== ''}
					imageSrc={focus_state_icons?.warningNew}
					heart={true}
				/>
			)}
			{modalOpen?.state && addition?.loading === false && (
				<Edenredmodal
					title="Addition Cannot be Unselected"
					desc={`Addition is a part of your employees' contract and cannot be edited `}
					onOkay={() => setModalOpen({ state: false, value: '' })}
					onOkayButton="Ok, Got it"
					isOpen={modalOpen?.state}
					imageSrc={focus_state_icons?.warningNew}
					heart={true}
				/>
			)}
			{showModal === 'AddNewAddition' && (
				<Edenredmodal
					field="Enter Addition"
					fieldlabel="Add New Addition"
					onOkay={() => addNewAddition(newAdditionItem)}
					onCancelButton="Cancel"
					onOkayButton="Add"
					onOkayDisable={newAdditionItem.trim() === '' || isAlreadyExists}
					onCancel={closeModal}
					isOpen={showModal !== ''}
					close={closeModal}
					fieldonchange={handleChange}
					fieldValue={newAdditionItem}
					errorMessage={isAlreadyExists && 'Addition already exists'}
				/>
			)}
		</>
	);
};

export default Addition;
