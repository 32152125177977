export const UN_EMPLOYMENT_TAB_KEYS = {
    SUBSCRIBED: 'subscribed',
    MISSING_DETAILS: 'missing_details',
    PENDING: 'pending',
    CANCELLED: 'cancelled',
};

export const UN_EMPLOYMENT_TAB_LABELS = {
    SUBSCRIBED: 'Subscribed',
    MISSING_DETAILS: 'Missing Details',
    PENDING: 'Pending',
    CANCELLED: 'Cancelled',
};

export const UN_EMPLOYMENT_INSURANCE_TABS_CONSTANTS = {
    // Missing details tab
    missingDetails: {
        errorMessages: {
            invalidEmiratesId: 'Emirates ID should start with 784 and should 15 digit',
            invalidMobileNumber: 'Mobile Number should start with 009715 and should 14 digit'
        }
    }
}