import { Col, Row } from 'antd';
import React, { useEffect, useState, useCallback } from 'react';
import Modal from 'react-modal';
import ImageComponent from '@sharedComponent/image-component';
import IframeComponent from '@sharedComponent/iframe-component';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import Edenredmodal from '@pages/DesignSystem/Edenredmodal';
import { icons } from 'src/assets/icons';
import PayrollService from 'src/services/payroll-service';

const ViewSubmittedProof = ({
	closeSubmittedProofModal,
	setViewSubmittedProofModal,
	getAllFundTransferHistory,
	content,
	viewSubmittedProof,
	customStyles,
	view = 'desktop',
	refreshData,
	buttonText,
	buttonStyle,
	leaveToApproveImage,
	leaveToApproveDownload,
	attachmentname,
	setToastMessageShow,
	setToastMessage,
}) => {
	const [deleteConfirmSubmittedProof, setDeleteConfirmSubmittedProof] = useState(false);
	const [file, setFile] = useState({
		loading: true,
		url: null,
	});

	const [popupVisible, setpopupVisible] = useState(false);
	const [popupMessage, setPopupMessage] = useState({
		titleMessage: '',
		subMessage: '',
	});
	const [filename, setFileName] = useState('');
	const [openDeleteMobileModal, setOpenDeleteMobileModal] = useState(false);
	const _payrollService = new PayrollService();
	const handleOpenDeleteModal = () => {
		setDeleteConfirmSubmittedProof(true);
		setOpenDeleteMobileModal(false);
	};
	const getImageURL = () => {
		setFile({
			...file,
			loading: true,
		});
		_payrollService
			.getProofOfTransferFileURL({ receiptId: content?.id })
			.then((res) => {
				const objectURL = URL.createObjectURL(res);
				setFile({
					...file,
					loading: false,
					url: objectURL,
				});
			})
			.catch((err) => {
				setFile({
					...file,
					loading: false,
				});
			});
	};

	const handleCloseDelete = () => setDeleteConfirmSubmittedProof(false);

	const handleCloseDeleteModal = () => {
		setDeleteConfirmSubmittedProof(false);
		deleteReceiptApi();
	};

	const [loading, setloading] = useState(false);

	const deleteReceiptApi = async () => {
		setloading(true);
		_payrollService
			.deleteReceiptDetails(content.id)
			.then((res) => {
				if (res) {
					setloading(false);
					setViewSubmittedProofModal(false);
					closeSubmittedProofModal(false);
					getAllFundTransferHistory();
					setToastMessageShow(true);
					// setToastMessage
					// setpopupVisible(true);
					// setPopupMessage({
					// 	titleMessage: `Your proof of funds has been deleted.`,
					// 	subMessage: null,
					// });
				} else {
					closeSubmittedProofModal(false);
					setloading(false);
				}
			})
			.catch((err) => {
				closeSubmittedProofModal(false);
				setloading(false);
				refreshData && refreshData();
			});
	};

	useEffect(() => {
		if (leaveToApproveImage) {
			const val = attachmentname.substr(attachmentname.lastIndexOf('.') + 1);
			setFileName(val);
			setFile({
				...file,
				loading: false,
				url: leaveToApproveImage,
			});
		}
		viewSubmittedProof && content?.id && getImageURL();
	}, [viewSubmittedProof, leaveToApproveImage]);
	const { url } = file;
	const onButtonClick = useCallback((e, url) => {
		const imageUrl = url;
		if (e.preventDefault) {
			e.preventDefault();
		}

		if (!imageUrl.length) {
			return;
		}

		const encoded = encodeURIComponent(imageUrl);
		_payrollService
			.imageDownload(encoded)
			.then((response) => {
				const url = window.URL.createObjectURL(response.data);
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', `attachment.${filename}`);
				document.body.appendChild(link);
				link.click();
				link.remove();
			})
			.catch((error) => {
				console.log(error);
				return error;
			});
	});
	window.onclick = function (event) {
		if (event.target.className === 'ReactModal__Overlay ReactModal__Overlay--after-open') {
			closeSubmittedProofModal();
		}
	};

	const closePopup = () => {
		setpopupVisible(false);
	};

	return (
		<>
			<Modal isOpen={viewSubmittedProof} style={customStyles} centered className="view-submited-proof-custom">
				<Row style={{ display: 'flex' }} className="view-submited-row-popup">
					{view === 'desktop' && (
						<Col style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }} className="view-sub-col2">
							<ImageComponent
								src={icons.not_focus.Close}
								style={{
									cursor: 'pointer',
									height: '25px',
									width: '25px',
									filter: 'invert(100%) sepia(90%) saturate(2%) hue-rotate(337deg) brightness(104%) contrast(101%)',
								}}
								onClick={closeSubmittedProofModal}
							/>
						</Col>
					)}
					<Col
						style={{
							backgroundColor: 'white',
							height: view === 'desktop' ? (buttonText ? '80vh' : '70vh') : '435px',
							width: view === 'desktop' ? '415px' : '329px',
							minWidth: view === 'desktop' ? '415px' : '329px',
							overflow: 'auto',
						}}
						className="view-sub-col1">
						{leaveToApproveDownload ? (
							<>
								{filename === 'pdf' ? (
									<IframeComponent src={url} loading={file.loading} height="100%" width="100%" />
								) : (
									<ImageComponent src={url} style={{ width: '100%', height: 'auto' }} />
								)}
							</>
						) : (
							<IframeComponent src={url} loading={file.loading} height="100%" width="100%" />
						)}
					</Col>
					<Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', padding: '10px' }}>
						<EdenredButtons
							btnType="secondary"
							loading={loading}
							style={
								buttonStyle
									? buttonStyle
									: {
											backgroundColor: 'white',
											width: '225px',
									  }
							}
							leftIcon={
								<ImageComponent
									src={buttonText ? icons.focus_state?.downloadAttachement : icons.focus_state.NewDelete}
									style={{ height: '20px', width: '25px' }}
								/>
							}
							onClick={(e) => (leaveToApproveDownload ? onButtonClick(e, url) : handleOpenDeleteModal())}>
							{buttonText ? buttonText : 'Delete Proof Of Funds'}
						</EdenredButtons>

						<Edenredmodal
							isOpen={deleteConfirmSubmittedProof}
							onOkay={handleCloseDeleteModal}
							onCancel={handleCloseDelete}
							textcenter={true}
							onCancelButton="Cancel"
							onOkayButton="Delete"
							className="modal_card_approve"
							title="Delete Proof Of Fundsfddf"
							desc="Do you want to delete this proof of funds?"
						/>
					</Col>
					{view === 'mobile' && (
						<Col style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
							<EdenredButtons
								type="outline-g"
								style={{
									backgroundColor: 'white',
									fontFamily: 'Montserrat-Regular',
									fontSize: '13px',
									color: '#00344E',
									borderRadius: '22px',
									minHeight: '32px',
									width: '101px',
									padding: '0',
								}}
								icon={<ImageComponent src={icons.not_focus.Close} style={{ height: '20px', width: '20px' }} />}
								onClick={closeSubmittedProofModal}>
								Close
							</EdenredButtons>
						</Col>
					)}
				</Row>
			</Modal>
		</>
	);
};

export default ViewSubmittedProof;
