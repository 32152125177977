import ETypo from '@pages/DesignSystem/Typo';
import FlagImage from '@sharedComponent/FlagImage';
import moment from 'moment';
import React from 'react';

const OptionsContent = ({
	data,
	handleDropdown,
	handleToggle,
	mainData,
	icon,
	isOpen,
	dropDownValue,
	dateFormat,
	justifyOption,
}) => {
	const handleCheck = (clickedData, mainData) => {
		data?.subMenu?.length > 0 ? handleToggle() : handleDropdown(clickedData, mainData);
	};
	const dropdownInnerList = () => {
		if (dateFormat) {
			return moment(data?.[dropDownValue]).format(dateFormat)
		}
		const { shortCode, stdCode, countryName } = data || {}
		return <>
			<FlagImage flagCode={shortCode} alt={countryName} />
			{stdCode &&
				<span className='code'>
					+{stdCode}
				</span>
			}
			<ETypo className='countryName' light b2 color='#9A9EA6'>
				{countryName}
			</ETypo>
		</>
	}
	return (
		<div className="dropdown-list-sub" style={{ paddingLeft: isOpen ? 12 : 0 }}>
			<div
				className="dropdown_inner_list"
				onClick={() => handleCheck?.(data, mainData)}
				style={{ justifyContent: justifyOption ? 'space-between' : '', width: '100%' }}>
				<div className="dropdown_inner_list_main" style={{
					display: 'flex',
					gap: '8px',
					alignItems: 'center',
				}}>
					{dropdownInnerList()}
				</div>
			</div>

			{data?.subMenu?.length > 0 && (
				<div role="button" onClick={() => handleToggle()}>
					{icon}
				</div>
			)}
		</div>
	);
};
export default OptionsContent;
