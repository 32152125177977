import React from 'react';
import TextContent from './TextContent';
import VideoContent from './VideoContent';

const HrSummaryContents = ({ contentList = [], id, handleChange = () => {} }) => {
	return (
		<main className="intro-content-container">
			{/* here we get list of new hr features and their demo video */}
			{contentList?.map((content, index) => id === index + 1 && <VideoContent key={index} {...{ content }} />)}
			<section className="list-container">
				{contentList?.map((content, index) => {
					return <TextContent key={index} {...{ content, handleChange, id, index }} />;
				})}
			</section>
		</main>
	);
};

export default HrSummaryContents;
