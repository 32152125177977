import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ImageComponent from '@sharedComponent/image-component';
import Edenredmodal from '@pages/DesignSystem/Edenredmodal';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import ClevertapReact from 'src/utils/clever-tap';
import { focus_state_icons } from '@assets/icons/focus_state';
import { not_focus_icons } from '@assets/icons/not_focus';
import action from '../action';
import sharedActions from '@sharedComponent/app-action-reducer/action';
import { images } from '@assets/img';

const HrSummaryHeader = ({ contentList, id, handleChange }) => {
	const [modalOpen, setModalOpen] = useState(false);
	const history = useHistory();
	const dispatch = useDispatch();

	const pushToDashboard = (mode) => {
		mode === 'push' && ClevertapReact.event('V1_1_Skip_later');
		dispatch(action.creators.setHrSetupStatusSkipped(true));
		history.push('/');
	};

	const postAndPushToDashboard = () => {
		ClevertapReact.event('V1_1_Skip_never');
		dispatch(sharedActions.creators.postHrSetupStatus({ hrOnBoardingFlowCompleted: true }));
		pushToDashboard('post');
	};
	const closeModal = () => {
		setModalOpen(false);
	};
	return (
		<header className="header-container">
			<h2 className="header-title">
				<span className="header-title-sub">NEW!</span> You have exclusive access to HR features!
			</h2>
			{contentList.length >= 0 && (
				<div className="header-select-btn">
					<ImageComponent
						alt="selectLeftBtn"
						className="select-btn"
						src={id === 1 ? not_focus_icons.selectLeftArrow : focus_state_icons.selectLeftArrow}
						onClick={() => {
							id !== 1 && handleChange('sub');
						}}
					/>
					{id !== contentList.length ? (
						<ImageComponent
							src={focus_state_icons.selectRightArrow}
							alt="selectRightBtn"
							className="select-btn"
							onClick={() => handleChange('add')}
						/>
					) : (
						<span>
							<EdenredButtons
								children={'Skip'}
								btnType="secondary"
								disabled={false}
								onClick={() => {
									ClevertapReact.event('v1_1_Intro_Leave_Skip');
									setModalOpen(true);
								}}
								rightIcon={<ImageComponent src={images?.close} />}
							/>
						</span>
					)}
					<Edenredmodal
						title="You are skipping"
						desc="You’ll be able to continue from the Payroll and Dashboard in the future."
						onOkay={() => pushToDashboard('push')}
						onCancelButton="Don’t Show Again"
						onOkayButton="Do It Later"
						onCancel={postAndPushToDashboard}
						isOpen={modalOpen}
						heart={true}
						imageSrc={focus_state_icons.warningNew}
						close={closeModal}
					/>
				</div>
			)}
		</header>
	);
};

export default HrSummaryHeader;
