import React, { useState } from 'react';
import { Col } from 'antd';
import Tooltip from '@pages/DesignSystem/Tool-Tip';
import PayrollService from 'src/services/payroll-service';
import moment from 'moment';
import TooltipContent from './TooltipContent';
import ETypo from '@pages/DesignSystem/Typo';
import { API_STATUS_CODES } from '@constants/app-constants';
import { PAYROLL_CONSTANTS } from '@pages/payroll/constants';
import './totalSalaryPaid.scss';

const TotalSalaryPaid = ({ total, data, dateRange, index }) => {
	const [loading, setLoading] = useState(false);
	const [toolTipData, setToolTipData] = useState(null);
	const [error, setError] = useState(null);

	async function checkTooltipData() {
		const payrollService = new PayrollService();
		// If the tooltip has no data and its not loading means will hit api call
		if (!toolTipData && !loading) {
			setLoading(true);
			// Have to use employee code as employee id as per BE logic
			const employeeId = data?.employeeCode;
			// Salary month and year
			const salMonth = moment(dateRange?.salaryDate).format('MM');
			const salYear = moment(dateRange?.salaryDate).format('YYYY');
			try {
				const res = await payrollService.getTotalSalaryPaidTooltip(employeeId, salMonth, salYear);
				// If we receive 500 due to api failure will throw error
				if (res.data?.StatusCode === API_STATUS_CODES.INTERNAL_ERROR) {
					throw new Error(PAYROLL_CONSTANTS.table.paidTooltip.errorMsg);
				}
				if (res.data) {
					setLoading(false);
					setToolTipData(res.data);
				}
			} catch (error) {
				setLoading(false);
				if (error?.message) {
					setError(error?.message);
				}
			}
		}
	}
	function popupDirection() {
		/* 
		if the index was 0 means its a first row on table on paid column,
		So for those if we have tooltip data we show in direction down ,
		beacuse the tooltip not visisble fully outside of the table if we have more contents due to overflow property on table outer
		it restricting tooltip for not going outside, for that making first index alone at direction down.
		*/
		if (index === 0 && total) {
			return 'down';
		}
		return 'up';
	}

	function onHoverLoadingData() {
		// while hovering the paid column, if total paid value not 0 then will checkTooltipData
		if (total) {
			return checkTooltipData();
		}
		return () => {};
	}

	function onMouseLeave() {
		// Clearing the error msg if it exists in state.
		if (error) {
			setError(null);
		}
	}

	return (
		<Tooltip
			data-testid="test-paid-tooltip"
			className="paid-tooltip"
			direction={popupDirection()}
			content={<TooltipContent {...{ loading, toolTipData, total, error }} />}>
			<Col data-testid="test-paid-value" onMouseLeave={onMouseLeave} onMouseEnter={onHoverLoadingData}>
				<ETypo medium b1 className="paid-text">
					{`${total} Paid`}
				</ETypo>
			</Col>
		</Tooltip>
	);
};

export default TotalSalaryPaid;
