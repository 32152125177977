export const INVOICE_STATUSES = Object.freeze({
    new: 'new',
    autoSuccess: 'autosuccess',
    autofailed: 'autofailed',
    manualsuccess: 'manualsuccess',
    pending: 'pending',
    rejected: 'rejected',
    generalerror: 'generalerror',
    manualfailedDisputed: 'manualfailed-disputed'
})
export const INVOICE_STATUS_TEXT = Object.freeze({
    success: 'Payment received',
    pending: 'Pending reconciliation from Edenred',
    reject: 'Rejected'
})

export const INVOICE_LEFT_INFO_SECTION = Object.freeze({
    viewAllInvoices: 'View All Invoices',
    statementOfAccounts: 'Statement of Accounts',
    paymentHistory: 'Payment History',
    timeSaverCard: {
        label: 'Time Saving Tip',
        description: 'Pay by Bank Transfer for automatic reconciliation'

    }
})