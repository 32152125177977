import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { isArray } from 'lodash';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import { not_focus_icons } from 'src/assets/icons/not_focus';
import { announcementClaimCheck, documentClaimCheck, leaveClaimCheck, payrollClaimCheck } from '@constants/app-constants';
import ContentLoader from '@sharedComponent/content-loader';
import ClevertapReact from 'src/utils/clever-tap';
import HrSummaryHeader from './components/HrSummaryHeader';
import HrSummaryContents from './components/HrSummaryContents';
import './HrFeatureSummary.scss';

const HrFeatureSummary = () => {
	const [id, setId] = useState(1);
	const isDeductionCompleted = useSelector((state) => state?.sharedStates?.userNavigation?.hrSetupDone);

	const { hrClaims, payrollClaims } = useSelector((state) => state?.sharedStates?.currentUser);

	let allCliams = [];
	if (isArray(hrClaims) && isArray(payrollClaims)) {
		allCliams = [...hrClaims, ...payrollClaims];
	}
	let contentList = [];

	const payrollList = [
		{
			id: 1,
			title: 'Speed up payroll processing',
			sub: 'Manage allowances, additions & deductions',
			icons: not_focus_icons.setupCalculatorIcon,
			focusIcons: focus_state_icons.setupcalculatoricon,
			videoSource: 'https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/HR/hrvideo1(aad).mp4',
			navigate: isDeductionCompleted ? '/new-hr-features/uploadPayrollDetails' : '/new-hr-features/allowance',
			cleverTapTryNow: 'v1_1_Intro_Payroll_TryNow',
			buttontext: 'Automate Payroll',
			alt: 'setupCalculatorIcon'
		},
		{
			id: 2,
			title: 'Get payslips automatically',
			sub: 'Payslips will include all the salary breakdowns',
			icons: not_focus_icons.documentPayslip,
			focusIcons: focus_state_icons.documentPayslip,
			videoSource: 'https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/HR/hrvideo2_payslips.mp4',
			navigate: '/new-hr-features/allowance',
			cleverTapTryNow: 'v1_1_Intro_Payslips_TryNow',
			buttontext: 'Get Payslips',
			alt: 'documentPayslipIcon'
		},
	];
	const documentList = {
		id: 3,
		title: 'Get alerts before documents expire',
		sub: `We'll remind you about documents' expiry status in advance`,
		icons: not_focus_icons.remainder,
		focusIcons: focus_state_icons.remainder,
		videoSource: 'https://eae-c3pay-cdn-p.azureedge.net/hr-videos/HRVideo3_DocumentManagement_1080.mp4',
		navigate: '/new-hr-features/documentsExpiry',
		cleverTapTryNow: 'v1_1_Intro_Docs_TryNow',
		buttontext: 'Manage Documents',
		alt: 'remainderIcon'
	};
	const announcementList = {
		id: 4,
		title: 'Send announcements to your employees',
		sub: 'Send instant notifications directly to Employees',
		icons: not_focus_icons.emails,
		focusIcons: focus_state_icons.emails,
		videoSource: 'https://eae-c3pay-cdn-p.azureedge.net/hr-videos/V2/HRVideo5_Announcements.mp4',
		navigate: '/new-hr-features/setupAnnouncement',
		cleverTapTryNow: 'v1_1_Intro_Announ_TryNow',
		buttontext: 'Try Announcements',
		alt: 'emailIcon'
	};

	const leaveList = {
		id: 5,
		title: 'Digitize and automate leave management',
		sub: 'Hassle-free Automatic leave balance calculation',
		icons: not_focus_icons.leavenew,
		focusIcons: focus_state_icons.autoLeaves,
		videoSource: 'https://eae-c3pay-cdn-p.azureedge.net/hr-videos/HRVideo4_LeaveManagement.mp4',
		navigate: '/new-hr-features/departments',
		cleverTapTryNow: 'v1_1_Intro_Leave_TryNow',
		buttontext: 'Manage Leaves',
		alt: 'sunriseIcon'
	};
	const cleverTap = (selectedId) => {
		switch (id) {
			case 1:
				return { CleverTapRight: 'v1_1_Intro_Payroll_Next', CleverTapLeft: 'v1_1_Intro_Payroll_Section' };
			case 2:
				return { CleverTapRight: 'v1_1_Intro_Payslips_Next', CleverTapLeft: 'v1_1_Intro_Payslips_Section' };
			case 3:
				return { CleverTapRight: 'v1_1_Intro_Docs_Next', CleverTapLeft: 'v1_1_Intro_Docs_Section' };
			case 4:
				return { CleverTapRight: 'v1_1_Intro_Announ_Next', CleverTapLeft: 'v1_1_Intro_Announ_Section' };
			case 5:
				return { CleverTapRight: 'v1_1_Intro_Leave_Next', CleverTapLeft: 'v1_1_Intro_Leave_Section' };
			default:
				return {};
		}
	};
	const handleChange = (contentId) => {
		if (contentId === 'add') {
			ClevertapReact.event(cleverTap(id)?.CleverTapRight);
			setId((prev) => prev + 1);
		} else if (contentId === 'sub') {
			ClevertapReact.event(cleverTap(id)?.CleverTapLeft);
			setId((prev) => prev - 1);
		} else {
			ClevertapReact.event(cleverTap(id)?.CleverTapLeft);
			setId(contentId);
		}
	};


	const list = allCliams.map((data) => {
		if (payrollClaimCheck.includes(data?.value)) return payrollList;
		if (documentClaimCheck.includes(data?.value)) return documentList;
		if (announcementClaimCheck.includes(data?.value)) return announcementList;
		if (leaveClaimCheck.includes(data?.value)) return leaveList;
	});
	const newList = list.flat().filter((data) => data != undefined);
	newList.sort((a, b) => a.id - b.id);
	contentList = Array.from(new Set(newList));

	// common props which sending for two components
	const commonProps = {
		contentList, id, handleChange
	}

	return (
		<div className="intro-container">
			<HrSummaryHeader {...commonProps} />
			{contentList.length <= 0 ? (
				<ContentLoader />
			) : (
				<HrSummaryContents {...commonProps} />
			)}
		</div>
	);
};

export default HrFeatureSummary;