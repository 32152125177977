export const SITEMAP = {
    dashboard: {
        index: '/',
        pendingAlerts: '/dashboard/pending-alerts'
    },
    auth: {
        mfa: '/mfa'
    },
    unemploymentInsurance: {
        employee: '/un-employment-insurance/employee',
        singleUpdate: '/un-employment-insurance/single-update',
        plan: '/un-employment-insurance/plan'
    },
    termsAndConditions: {
        index: '/terms-and-conditions'
    },
    invoice: {
        index: '/proof-of-invoice',
        history: '/proof-of-invoice/history',
        groupDetails: '/proof-of-invoice/group-details',
        viewAll: '/proof-of-invoice/view-all',
    },
    payroll: {
        salaryReports: {
            index: '/salary',
            cards: '/salary/cards',
            perEmployee: '/salary/salary_per_employee',
            history: '/salary-history',
            monthWiseReports: '/salary-monthwise_reports'

        },
    },
    employees: {
        add: '/employee/add',
        verification: {
            dashboard: {
                index: '/dashboard/employees-verification/update',
                employeePreview: '/dashboard/employees-verification/update/employee-preview',
                employeeUnderReview: '/dashboard/employees-verification/update/under-review',
            },
        }
    },
    user: {
        createPassword: '/create-password',
        loginStep: '/login-step',
        slider: '/slider',
        approvePage: '/approve_page'
    },
    help: {
        index: '/help',
        payroll: '/help/payroll',
        invoice: '/help/invoice',
        employee: {
            index: '/help/employee',
            atmPin: '/help/employee/ATMPinFaqs',
            cardOrder: '/help/employee/cardOrderFaqs',
            cardUsage: '/help/employee/cardUsage',
            newEmployee: '/help/employee/newEmpFaqs',
            employeeRequests: '/help/employee/empReqFaqs',
        },
        labourLaws: {
            index: '/help/labour-laws',
            wpsPenalty: '/help/labour-laws/wps-penalty',
            wpsRules: '/help/labour-laws/wps-rules',
            gratuityCalculate: '/help/labour-laws/gratuity-calculate',
            overTimePay: '/help/labour-laws/over-time-pay',
            leaveCalculation: '/help/labour-laws/leave-calculation'
        },
    }
}