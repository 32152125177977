import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { monthsNames } from '@constants/index';
import { getYears } from '@helpers';
import PayrollService from 'src/services/payroll-service';
import ClevertapReact from 'src/utils/clever-tap';
import WpsNonWpsPopupPresentational from './wps-nonwps-popup-presentational';
import payrollAction from '../../../payroll-plus-new/action';

const WpsNonWpsPopupFunctional = ({ isPopupVisible, setIsPopupVisible }) => {
	const _payrollService = new PayrollService();
	const history = useHistory();
	const [stagingEmployeeList, setStagingEmployeeList] = useState([]);
	const [emppStagingLoader, setEmppStagingLoader] = useState(false);
	const [downloadingExecl, setdownloadingExecl] = useState(false);
	const [EmpList, setEmplist] = useState(false);
	const [countEmp, setcountEmp] = useState('');
	const [showDateConfirmPopup, setShowDateConfirmPopup] = useState(false);
	const [savedSelectedDate, setSavedSelectedDate] = useState(null);
	const [monthYear, setMonthYear] = useState({
		month: '',
		year: '',
		proceedSelectedDate: '',
	});
	const [wpsLoader, setWpsLoader] = useState(false);
	const [wpsNonWpsDetail, setwpsNonWpsDetails] = useState([
		{
			title: 'Non-WPS',
			param: 'nonwps',
			description: "Your payroll wont be run under the Ministry of Labour's (MoL) WPS.",
			isSelected: false,
			id: 0,
		},
		{
			title: 'WPS',
			param: 'wps',
			description: "Your payroll will be run under the Ministry of Labour's (MoL) WPS.",
			isSelected: true,
			id: 1,
		},
	]);
	const dispatch = useDispatch();
	const getStagedEmployeesList = async (data, status) => {
		setWpsLoader(true);
		setEmppStagingLoader(true);
		await _payrollService
			.getStagedEmployeesListUrl(isPopupVisible?.monthYearSelect)
			.then((response) => {
				setStagingEmployeeList(response.data);
				let stagingEmpList = response.data;

				if (wpsNonwpsFilteredData?.[0]?.param === 'nonwps') {
					if (isPopupVisible?.type === 'payrollPlus' && isPopupVisible?.calledFrom !== 'SPREAD_SHEET_UPLOAD_SCREEN') {
						ClevertapReact.event('Next_MthYear', 'Non Wps');
						setIsPopupVisible({ ...isPopupVisible, channelOptions: wpsNonwpsFilteredData?.[0], data: data, status: 'nowOrLater' });
					} else {
						setIsPopupVisible({ ...isPopupVisible, channelOptions: wpsNonwpsFilteredData?.[0], data: data, status: 'monthYearSelect' });
					}
				} else {
					let empName = [];
					stagingEmpList?.length > 0 &&
						stagingEmpList.map((td) => {
							if (td?.establishmentId && td?.labourCardNo && (td?.wpsPersonId || td?.wpsPersonid) && td?.payrollChannel === 'WPS') {
							} else {
								if (td.employeCode) {
									empName.push({ employeeCode: td?.employeCode });
									setEmplist(empName);
								} else {
									empName.push({ employeeCode: td?.employeeCode });
									setEmplist(empName);
								}
							}
						});
					setcountEmp(empName?.length);
					if (empName?.length > 0) {
						setdownloadingExecl(true);
					} else {
						if (isPopupVisible?.type === 'payrollPlus' && isPopupVisible?.calledFrom !== 'SPREAD_SHEET_UPLOAD_SCREEN') {
							ClevertapReact.event('Next_MthYear', 'Wps');
							setIsPopupVisible({ ...isPopupVisible, channelOptions: wpsNonwpsFilteredData?.[0], data: data, status: 'nowOrLater' });
						} else {
							ClevertapReact.event('Next_SPChannel');
							setIsPopupVisible({
								...isPopupVisible,
								channelOptions: wpsNonwpsFilteredData?.[0],
								data: data,
								status: 'monthYearSelect',
							});
						}
					}
				}
				setEmppStagingLoader(false);
				setWpsLoader(false);
			})
			.catch((error) => {
				setEmppStagingLoader(false);
				setWpsLoader(false);
			});
	};

	useEffect(() => {
		// getStagedEmployeesList();
	}, [downloadingExecl]);

	const handlePopupCard = (param) => {
		const ndetail = wpsNonWpsDetail?.map((data) => {
			return {
				...data,
				isSelected: param === data?.param,
			};
		});
		setdownloadingExecl(false);
		setwpsNonWpsDetails(ndetail);
	};
	const handleMonthYear = (param, value) => {
		if (param === 'year') {
			setMonthYear({ ...monthYear, month: '', year: value });
		} else {
			setMonthYear({ ...monthYear, [param]: value });
		}
	};
	const wpsNonwpsFilteredData = wpsNonWpsDetail?.filter((data) => data?.isSelected);

	const monthDropDownList = useMemo(() => {
		const selectedYear = monthYear.year;
		const currentYear = new Date().getFullYear();
		const currentMonth = new Date().getMonth();
		const IS_PAST_YEAR = selectedYear ? parseInt(selectedYear) < parseInt(currentYear) : false;
		const IS_FUTURE_YEAR = selectedYear ? parseInt(selectedYear) > parseInt(currentYear) : false;
		let months = [...monthsNames];

		if (IS_PAST_YEAR === true) {
			months.splice(0, currentMonth + 1);
		} else if (IS_FUTURE_YEAR === true) {
			months.splice(currentMonth, 12 - currentMonth);
		}

		return months?.map((data) => {
			return {
				month: data,
			};
		});
	}, [monthYear.year]);

	const yearDropDownList = useMemo(() => {
		let yearsDuration = getYears(1, 1);
		const currentMonth = new Date().getMonth();
		if (currentMonth === 0) {
			yearsDuration = getYears(0, 1);
		} else if (currentMonth === 11) {
			yearsDuration = getYears(1, 0);
		}
		return yearsDuration.map((year) => {
			return {
				year: year,
			};
		});
	}, []);
	const handleWpsNonWps = async (data, status) => {
		getStagedEmployeesList(data, status);
	};
	const handleMonthYearSelect = (data, status) => {
		if (isPopupVisible?.data === 'wps') {
			let dateSelected;
			let currentYear = moment().format('YYYY');
			// if (data?.year >= currentYear) {
			// 	dateSelected = moment(`${data?.year}-${data?.month}-01`);
			// } else {
			dateSelected = moment(`${data?.year}-${data?.month}-01`);
			// }
			let stringDate = moment(dateSelected).format('MMMM YYYY');
			setSavedSelectedDate(stringDate);
			let matchMonth = moment().diff(dateSelected, 'months');
			// if (matchMonth > 2 || matchMonth < -1) {
			// 	setShowDateConfirmPopup(true);
			// } else {
			ClevertapReact.event('Next_MthYear', { Type: 'Wps' });
			setIsPopupVisible({ ...isPopupVisible, monthYearSelect: data, status: status });
			// }
		} else {
			ClevertapReact.event('Next_MthYear', { Type: 'Non Wps' });
			setIsPopupVisible({ ...isPopupVisible, monthYearSelect: data, status: status });
		}
	};
	const handleNowLater = (data, status, param) => {
		var currentPayrollRunDate = new Date();
		ClevertapReact.event(param === 'later' ? 'Later_PayrollDate' : 'Now_PayrollDate');
		setIsPopupVisible({ ...isPopupVisible, status: status, isWpsNonWps: param === 'later' });
		param === 'now' &&
			history.push({
				pathname: '/payroll/run/channel/submit_payroll',
				state: { ...isPopupVisible, payrollRunDate: currentPayrollRunDate },
			});
		if (param === 'now') {
			dispatch(payrollAction.creators.changeWpsNonWpsPopup(false));
		}
	};
	const handleSelectDate = (data, status, param) => {
		setIsPopupVisible({ ...isPopupVisible, selectDate: data, status: status, isWpsNonWps: param === 'cancel' });
		param === 'Proceed' &&
			history.push({
				pathname: '/payroll/run/channel/submit_payroll',
				state: { ...isPopupVisible, selectDate: data },
			});

		if (param === 'Proceed') {
			dispatch(payrollAction.creators.changeWpsNonWpsPopup(false));
		}
	};

	const handlePopup = () => {
		const ndetail = wpsNonWpsDetail?.map((data) => {
			return {
				...data,
				isSelected: data.param === 'wps' ? true : false,
			};
		});
		setwpsNonWpsDetails(ndetail);
		setMonthYear({
			month: '',
			year: '',
			proceedSelectedDate: '',
		});
		setdownloadingExecl(false);
		dispatch(payrollAction.creators.changeWpsNonWpsPopup(false));
		setIsPopupVisible({
			...isPopupVisible,
			isWpsNonWps: false,
			data: [],
			endofService: [],
			payrollPlus: {},
			runPayroll: {},
			message: '',
			wpsNonWps: '',
			monthYearSelect: {},
			selectDate: '',
			status: 'wpsNonWps',
			type: '',
		});
	};

	return (
		<WpsNonWpsPopupPresentational
			{...{
				isPopupVisible,
				handlePopup,
				monthYear,
				wpsNonWpsDetail,
				handlePopupCard,
				handleMonthYear,
				wpsNonwpsButtonDisable: wpsNonwpsFilteredData,
				monthDropDownList,
				yearDropDownList,
				handleWpsNonWps,
				handleMonthYearSelect,
				handleNowLater,
				handleSelectDate,
				emppStagingLoader,
				downloadingExecl,
				EmpList,
				countEmp,
				wpsLoader,
				setWpsLoader,
			}}
		/>
	);
};

export default WpsNonWpsPopupFunctional;
