import React, { useMemo, useState } from 'react';
import { Col, Row } from 'antd';
import { useHistory } from 'react-router-dom';
import ETypo from '@pages/DesignSystem/Typo';
import InvoiceService from 'src/services/invoice-service';
import CustomTab from './custom-tab';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import appActions from '@sharedComponent/app-action-reducer/action';
import actions from '../invoiceDetailAction';
import { useDispatch, useSelector } from 'react-redux';
import Toast from '@pages/DesignSystem/Toast';
import { getFormatedNumber } from './group-table';
import EmployeeService from 'src/services/employee-service';
import moment from 'moment';
import CustomToast from '@sharedComponent/toast';
import { API_STATUS_CODES } from '@constants/app-constants';
import { callCleverTapEvent } from '@helpers';
import { CLEVER_TAP_EVENTS_CONSTANTS } from '@constants/cleverTap';
import InvoiceLeftSection from './section/InvoiceLeftSection';

export const invoiceTabKeys = {
	BANK_TRANSFER: 'Bank Transfer',
	CASH_DEPOSITE_AT_ATM: 'ATM Cash Deposit',
	CASH_DEPOSITE_AT_BRANCH: 'Branch Cash Deposit',
	CHEQUE_DEPOSIT: 'Cheque',
};
export const invoiceTabValues = {
	BANK_TRANSFER_VALUE: 'Bank Transfer',
	CASH_DEPOSITE_AT_ATM_VALUE: 'ATM Cash Deposit',
	CASH_DEPOSITE_AT_BRANCH_VALUE: 'Branch Cash Deposit',
	CHEQUE_DEPOSIT_VALUE: 'Cheque Deposit',
};
export const initFormState = {
	InvoiceReceiptFile: null,
	Amount: 0,
	PaymentDate: '',
	PaymentTime: null,
	ReceiptType: invoiceTabValues.BANK_TRANSFER_VALUE,
	ATMDigit: '',
	ChequeNumber: '',
	Description: '',
	isSavedSuccess: false,
	ReferenceNumber: '',
};
const InvoicePresentational = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const _invoiceService = new InvoiceService();
	const employeeApi = new EmployeeService();
	const [loading, setloading] = useState(false);
	const [successToastForSubmit, setSuccessToastForSubmit] = useState(false);
	const [failedToastForSubmit, setFailedToastForSubmit] = useState(false);

	const [chequeDepositPayload, setChequeDepositPayload] = useState(initFormState);
	const { invoiceDetails, getInvoiceInfoLoading } = useSelector((state) => state?.sharedStates) || {};
	const invoiceInfo = invoiceDetails;
	const { invoiceStatementDownloadLoading, invoiceStatementMsg } = useSelector((state) => state?.invoiceState) || {};
	const onChangeInvoiceForm = (state) => {
		isAllowToSubmitInvoice();
		setChequeDepositPayload(state);
	};

	useMemo(() => {
		if (!invoiceDetails) {
			fetchInvoiceDetails();
		}
	}, []);

	async function fetchInvoiceDetails() {
		dispatch(appActions.creators.getInvoiceInfoStart());
		try {
			let response = await employeeApi.getInvoiceDetails();
			if (response && response.data) {
				dispatch(appActions.creators.getInvoiceInfoSuccess(response.data));
				return response.data;
			}
		} catch (err) {
			dispatch(appActions.creators.getInvoiceInfoFail());
		}
	}

	const clearInvoiceFileState = () => {
		setChequeDepositPayload({ ...chequeDepositPayload, InvoiceReceiptFile: null });
	};
	const resetAllInvoiceState = () => {
		setChequeDepositPayload(initFormState);
	};

	const invoiceTabArr = [
		{
			tabHeading: 'Bank Transfer',
			key: invoiceTabKeys.BANK_TRANSFER,
		},
		{
			tabHeading: 'Cash Deposit at ATM',
			key: invoiceTabKeys.CASH_DEPOSITE_AT_ATM,
		},
		{
			tabHeading: 'Cash Deposit at Branch',
			key: invoiceTabKeys.CASH_DEPOSITE_AT_BRANCH,
		},
		{
			tabHeading: 'Cheque Deposit',
			key: invoiceTabKeys.CHEQUE_DEPOSIT,
		},
	];

	const onSubmitProof = async (request = chequeDepositPayload) => {
		let formData = new FormData();
		formData.append('InvoiceReceiptFile', request?.InvoiceReceiptFile);
		formData.append('Amount', getFormatedNumber(request?.Amount || 0));
		formData.append('DepositDate', request?.PaymentDate?.length > 0 ? moment(request?.PaymentDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : '');
		formData.append('PaymentTime', request?.PaymentTime ? request?.PaymentTime?.format('HH:mm') : '');
		formData.append('ReceiptType', request?.ReceiptType);
		formData.append('ATMDigit', request?.ATMDigit || '');
		formData.append('Description', request?.Description?.length > 0 ? request?.Description : '');
		formData.append('ChequeNumber', request?.ChequeNumber || '');
		formData.append('ReferenceNumber', request?.ReferenceNumber || '');
		setChequeDepositPayload({ ...initFormState, isSavedSuccess: false });
		setloading(true);
		_invoiceService
			.submitInvoiceProof(formData)
			.then((res) => {
				if (res.data?.StatusCode === API_STATUS_CODES.INTERNAL_ERROR) {
					setloading(false);
					setFailedToastForSubmit(true);
					setChequeDepositPayload({ ...initFormState, isSavedSuccess: true, ReceiptType: chequeDepositPayload.ReceiptType });
				} else {
					setloading(false);
					setSuccessToastForSubmit(true);
					setChequeDepositPayload({ ...initFormState, isSavedSuccess: true, ReceiptType: chequeDepositPayload.ReceiptType });
					/**
					 * Group invoice
					 */
					if (invoiceInfo?.isGroup) {
						history.push({
							pathname: '/proof-of-invoice/group-details',
							state: { receiptAmt: chequeDepositPayload.Amount, receiptId: res.data },
						});
					}
				}
			})
			.catch(() => {
				setloading(false);
				setSuccessToastForSubmit(false);
				setFailedToastForSubmit(true);
				setChequeDepositPayload({ ...initFormState, isSavedSuccess: true, ReceiptType: chequeDepositPayload.ReceiptType });
			});
	};

	const handleServices = () => {
		callCleverTapEvent(CLEVER_TAP_EVENTS_CONSTANTS.invoice.submit_invoiceproof);
		onSubmitProof(chequeDepositPayload);
	};

	const isAllowToSubmitInvoice = () => {
		const { InvoiceReceiptFile, Amount, PaymentDate, PaymentTime, ReceiptType, ATMDigit, Description, ChequeNumber, ReferenceNumber } =
			chequeDepositPayload;

		switch (ReceiptType) {
			case invoiceTabKeys.BANK_TRANSFER:
				if (!InvoiceReceiptFile || Amount <= 0 || PaymentDate === '' || PaymentTime === '' || PaymentTime === null) {
					return true;
				} else {
					return false;
				}
			case invoiceTabKeys.CASH_DEPOSITE_AT_ATM:
				if (!InvoiceReceiptFile || Amount <= 0 || PaymentDate === '' || !ReferenceNumber) {
					return true;
				} else {
					return false;
				}
			case invoiceTabKeys.CASH_DEPOSITE_AT_BRANCH:
				if (!InvoiceReceiptFile || Amount <= 0 || PaymentDate === '' || !Description) {
					return true;
				} else {
					return false;
				}
			case invoiceTabKeys.CHEQUE_DEPOSIT:
				if (
					!InvoiceReceiptFile ||
					Amount <= 0 ||
					PaymentDate === '' ||
					PaymentTime === '' ||
					PaymentTime === null ||
					!ATMDigit ||
					!ChequeNumber
				) {
					return true;
				} else {
					return false;
				}

			default:
				break;
		}
	};

	return (
		<>
			{invoiceStatementMsg && (
				<Toast
					color="#8EF9B3"
					visible={true}
					clearPopUp={() => {
						dispatch(actions.creators.downloadCompanyStatementSuccess(null));
					}}>
					{invoiceStatementMsg}
				</Toast>
			)}
			<CustomToast
				success
				visible={successToastForSubmit}
				clearPopUp={() => {
					setSuccessToastForSubmit(false);
				}}>
				Saved Successfuly.
			</CustomToast>
			<CustomToast
				error
				visible={failedToastForSubmit}
				clearPopUp={() => {
					setFailedToastForSubmit(false);
				}}>
				Something went wrong.
			</CustomToast>
			<Col className="proof_of_invoice_presentational_container fade-right">
				<Row className="Proof-of-transfer-presentational">
					<Col className="proof_of_invoice_transfer_header">
						<ETypo bold h2>
							Invoice & Proof of Payment
						</ETypo>
						<EdenredButtons loading={loading} disabled={isAllowToSubmitInvoice() || getInvoiceInfoLoading} onClick={handleServices}>
							Submit
						</EdenredButtons>
					</Col>
					<Col
						xl={24}
						lg={24}
						md={24}
						sm={24}
						xs={24}
						className="proof_of_find_transfer_tab"
						style={{ display: 'flex', alignItems: 'flex-start' }}>
						{/* Invoice left section */}
						<InvoiceLeftSection {...{ invoiceInfo, getInvoiceInfoLoading, invoiceStatementDownloadLoading }} />
						{/* Invoice right tab section */}
						<section style={{ width: '75%' }}>
							<CustomTab
								tabArr={invoiceTabArr}
								{...{ onChangeInvoiceForm, chequeDepositPayload, clearInvoiceFileState, resetAllInvoiceState }}
							/>
						</section>
					</Col>
				</Row>
			</Col>
		</>
	);
};
export default InvoicePresentational;
