import React, { useState, useEffect, useRef } from 'react';
import { Col, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import { gsap } from 'gsap';
import ImageComponent from '@sharedComponent/image-component';
import ContentLoader from '@sharedComponent/content-loader';
import { amountWithDecimalsCount, checkHrSubscription } from '@helpers';
import PayrollNoData from '@pages/payroll/payrollNodata/PayrollNoData';
import { APP_VARIABLES } from '@constants/app-constants';
import { CustomAlertNotification } from '@pages/Leave-Management/request-leave/alert-notification/alert-notification';
import Modalcarousel from '@sharedComponent/modal-carousel';
import ETypo from '@pages/DesignSystem/Typo';
import MultiSelect from '@pages/DesignSystem/Multiselect/MultiSelect';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import Table from '@pages/DesignSystem/Table/Table';
import WpsNonWpsPopupFunctional from '@pages/payroll/run-payroll/components/wps-nonwps-popup-functional';
import Pagination from '@pages/DesignSystem/Table/Pagination';
import { icons } from 'src/assets/icons';
import { not_focus_icons } from 'src/assets/icons/not_focus';
import ClevertapReact from 'src/utils/clever-tap';
import UserService from 'src/services/user-service';
import useOnClickOutside from './useOnClickOutside';
import PayrollPlusNewTableHeader from './payroll-plus-new-table-header';
import TransferFundPopUpFunctional from './TransferFundPopUpFunctional';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import { useHistory, useLocation } from 'react-router-dom';
import { UnemploymentInsuranceShortBanner } from '@pages/payroll/run-payroll/components/unemployment-insurance-short-banner';
import setupAction from '../../../components/shared/app-action-reducer/action';
import { PAYROLL_CONSTANTS } from '@pages/payroll/constants';
import EdenredDropdown from '@pages/DesignSystem/Dropdown/EdenredDropdown';
import Action from '@sharedComponent/app-action-reducer/action';
import '../payroll-plus-new.scss';

const PayrollPlusNewPresentational = ({
	columns,
	dropDownValueState,
	payrollFilter,
	setPayrollFilter,
	empData,
	onChangeHandler,
	empCount,
	payrollTableLoader,
	handlePagination,
	payrollSalaryStatus,
	scrollEvent,
	toogleScroll,
	handleSave,
	popupFlag,
	totalSalary,
	employeeTotalCount,
	selectionListener,
	handleNext,
	onChange,
	date,
	setDate,
	popupValue,
	requestParams,
	onChangePopup,
	nextHide,
	buttonDisable,
	userNavigationMenu,
	handleSelectAll,
	handleChange,
	isPopupVisible,
	setIsPopupVisible,
	setIsAllSelected,
	setDropDownValueState,
	editedEmpDataByPage,
	setEditedEmpDataByPage,
	setEmpData,
	isSalaryExceeds,
	resetCardLoadStatus
}) => {
	const hrOnBoardingFlowCompleted = useSelector((state) => state?.sharedStates?.userNavigation?.hrOnBoardingFlowCompleted) || false;
	const isEmployeeNotUpload = useSelector((state) => state?.hrSetup?.isEmployeeNotUpload) || false;
	const payrollPlusBanner = useSelector((state) => state?.sharedStates?.userNavigation?.payrollPlusBanner) || false;
	const hrSetupDone = useSelector((state) => state?.sharedStates?.userNavigation?.hrSetupDone) || false;
	const [arrow, setArrow] = useState({ PayrollChannel: false, AccountType: false, EstablishmentID: false, BankName: false });
	const [payrollPlusBannerShow, setPayrollPlusBannerShow] = useState(false);
	const [handleNextClick, setHandleNextClick] = useState(false);

	useEffect(() => {
		setPayrollPlusBannerShow(true);
	}, []);
	const dispatch = useDispatch();
	const ref = useRef(null);
	const history = useHistory();
	let tableRef = useRef(null);
	gsap.registerPlugin(ScrollToPlugin);
	useOnClickOutside(ref, () => {
		if (payrollFilter) setPayrollFilter(false);
	});
	const location = useLocation();
	const salaryMonthData = location?.state?.salaryMonthData;
	const totalSalaryFormated = amountWithDecimalsCount(totalSalary);
	const handlePayrollPlusFilterSelect = (data) => {
		handleButton(data?.label);
		handleChange(data?.label, data?.value, data?.selected);
	};
	const { payrollPlusEmpTableData } = useSelector((state) => state?.counter);
	const handleButton = (data) => {
		switch (data) {
			case 'Payroll Channel':
				ClevertapReact.event('Filter_PayrollChannel');
				setArrow({ ...arrow, PayrollChannel: !arrow.PayrollChannel });
				break;
			case 'Account Type':
				ClevertapReact.event('Filter_AccountType');
				setArrow({ ...arrow, AccountType: !arrow.AccountType });
				break;
			case 'Establishment ID':
				ClevertapReact.event('Filter_EstID');
				setArrow({ ...arrow, EstablishmentID: !arrow.EstablishmentID });
				break;
			case 'Bank Name':
				ClevertapReact.event('Filter_BankName');
				setArrow({ ...arrow, BankName: !arrow.BankName });
				break;
		}
	};
	// const salaryDates = { salaryDate: '2022-10-04T14:10:30.4295694+04:00', salaryProcessStatus: 'Salaries Paid' };
	const handleDate = (data) => {
		return data.map((item) => {
			if (moment().format('MM YYYY') === moment(item?.salaryDate).format('MM YYYY') && item?.salaryProcessStatus === 'Salaries paid') {
				setDate({
					salaryDate: item?.salaryDate,
					salaryProcessStatus: item?.salaryProcessStatus,
				});
			} else {
				const final = data?.filter((final) => final?.salaryProcessStatus === 'Salaries Paid');
				const dataa = final?.length - 1;
				if (
					salaryMonthData
						? moment(salaryMonthData?.salaryDate).format('MM YYYY') === moment(item?.salaryDate).format('MM YYYY')
						: moment(final[dataa]?.salaryDate).add(1, 'M').format('MM YYYY') === moment(item?.salaryDate).format('MM YYYY')
				) {
					setDate({
						salaryDate: item?.salaryDate,
						salaryProcessStatus: item?.salaryProcessStatus,
					});
				}
			}

			return item;
		});
	};
	const leaveDetail = [
		{
			name: 'Name,Employee ID',
			value: payrollSalaryStatus?.map((salariesStatusData) => {
				if (salariesStatusData?.salaryProcessStatus === 'Salaries Paid') {
					return { ...salariesStatusData, salaryProcessStatus: 'Paid', isActive: false };
				} else if (salariesStatusData?.salaryProcessStatus === 'Salaries Due') {
					return { ...salariesStatusData, salaryProcessStatus: 'Salaries Due', isActive: true };
				} else {
					return salariesStatusData;
				}
			}),
			param: 'nameId',
		},
	];
	const tableLoading = {
		spinning: payrollTableLoader,
		indicator: <ContentLoader />,
	};
	let locale = {
		emptyText: (
			<div>
				<PayrollNoData tableLoading={tableLoading.spinning} />
			</div>
		),
	};
	var payroll = requestParams.payrollChannel;
	var payroll1 = payroll === '' ? 0 : payroll.split(',');
	var payrollcount = payroll1.length;
	var account = requestParams.accountType;
	var account1 = account === '' ? 0 : account.split(',');
	var accountcount = account1.length;
	var bank = requestParams.bankName;
	var bank1 = bank === '' ? 0 : bank.split(',');
	var bankcount = bank1.length;
	var establishment = requestParams.establishment;
	var establishment1 = establishment === '' ? 0 : establishment.split(',');
	var establishmentcount = establishment1.length;
	var selectcount =
		(payrollcount === undefined ? 0 : payrollcount) +
		(accountcount === undefined ? 0 : accountcount) +
		(establishmentcount === undefined ? 0 : establishmentcount) +
		(bankcount === undefined ? 0 : bankcount);
	useEffect(() => {
		handleDate(payrollSalaryStatus);
	}, [payrollSalaryStatus]);
	const [popupNew, setPopupNew] = useState(false);
	const [nextSlide, setNextSlide] = useState(0);
	const MenuAPI = new UserService();
	let navbarClaims = useSelector((state) => state?.sharedStates?.userNavigation);
	const isSubcribed = checkHrSubscription(JSON.parse(localStorage.getItem(APP_VARIABLES.PROD_SUBSCRIPTIONS)));
	const isOnlyEmployeeExits = useSelector((state) => state?.sharedStates?.isOnlyEmployeeExits);
	const updateStagingLoader = useSelector((state) => state?.counter?.updateStagingLoader) || false;
	const updateStagingMode = useSelector((state) => state?.counter?.updateStagingMode) || '';
	const featuresFlag = useSelector((state) => state?.sharedStates?.features)

	useEffect(() => {
		if (!isOnlyEmployeeExits && navbarClaims && (!navbarClaims?.tourGuide || !navbarClaims?.tourGuide?.includes('payroll')) && isSubcribed) {
			setPopupNew(true);
		} else {
			setPopupNew(false);
		}
	}, [navbarClaims]);
	// When trying to run payroll by doing save and continue will check for feature flag if it not already exist
	useEffect(() => {
		if (handleNextClick && featuresFlag) {
			handleNext(moment(date?.salaryDate).format('MM'), moment(date?.salaryDate).format('YYYY'));
			setHandleNextClick(false)

		}
	}, [featuresFlag])
	const handleClose = () => {
		setPopupNew(false);
	};
	const dataObj = [
		{
			image: not_focus_icons.Payro1,
			text: 'Store and manage employee contract information',
		},
		{
			image: not_focus_icons.Payroll2,
			text: 'Automate your payroll and overtime calculations',
		},
		{
			image: not_focus_icons.Payroll3,
			text: 'Your employees get payslips with breakdowns of salaries',
		},
		{
			image: not_focus_icons.Payroll4,
			text: 'Download department-wise salary reports',
		},
	];
	const handleSlideChange = (flag, status) => {
		let payload = {
			...navbarClaims,
			tourGuide: navbarClaims?.tourGuide ? `${navbarClaims?.tourGuide},${status}` : status,
		};
		if (nextSlide < dataObj?.length - 1) {
			setNextSlide((prev) => prev + 1);
		}
		if (flag == false) {
			MenuAPI.postUserNavigationMenu(payload)
				.then((res) => {
					userNavigationMenu();
					setPopupNew(false);
				})
				.catch((error) => {
					setPopupNew(false);
				});
		}
	};

	const handleFilterButton = () => {
		ClevertapReact.event('Filters_PayrollPlustable');
		setPayrollFilter(!payrollFilter);
	};

	const handleSaveAndContinue = () => {
		if (!featuresFlag) {
			setHandleNextClick(true)
			dispatch(Action.creators.checkFeaturesFlag())
		} else {
			handleNext(moment(date?.salaryDate).format('MM'), moment(date?.salaryDate).format('YYYY'));
		}
	}
	const handleSaveAndContinueLoader = () => {
		if (updateStagingMode === 'next') {
			return updateStagingLoader
		}
		if (handleNextClick) {
			return true
		}
		return false
	}

	return (
		<Row className="payroll-plus-new-top">
			{/* <EdenredModal isOpen={true} /> */}
			<CustomAlertNotification
				{...{
					when: onChangePopup,
					message: 'Unsaved Changes',
					description: 'Are you sure you want to leave this page? Changes may not be saved',
					okText: 'Leave',
					cancelText: 'Cancel',
					onOK: () => {
						resetCardLoadStatus()
						return true
					},
					onCancel: () => true,
					popupValue,
					selectedTab: '2',
				}}
			/>
			{/* To display banner for non-subscribed unemployment insurance corprates */}
			<UnemploymentInsuranceShortBanner />
			{/* End */}
			<Col xs={24} className="payroll-plus-new">
				<div className="payroll_plus_new_header">
					<div className='payroll_plus_title_calendar'>
						<ETypo bold h2 className="payroll-plus-typo">
							{PAYROLL_CONSTANTS.runPayroll.header.title}
						</ETypo>
						<div className="payroll_salary_dropdown">
							<EdenredDropdown
								{...{
									Option: leaveDetail?.[0]?.value,
									value: date?.salaryDate,
									onChange: onChange,
									searchHolder: 'Search',
									dropDownValue: 'salaryDate',
									dropDownSubValue: 'salaryProcessStatus',
									dateFormat: 'MMMM YYYY',
									showIcon: false,
									prefix: <ImageComponent src={not_focus_icons?.gradientCalender} alt='calendar-icon' />,
								}}
							/>
						</div>
					</div>
					<div className="run_payroll_plus">
						<div className="payroll_plus_amount">
							<ETypo light b1 color="#737780">
								{PAYROLL_CONSTANTS.runPayroll.header.payrollAmountText}
							</ETypo>
							<ETypo medium b1 color="#00344E">{`AED ${totalSalaryFormated} for ${employeeTotalCount.size} employees`}</ETypo>
						</div>
						<span>
							<EdenredButtons
								disabled={empData?.editedData?.length < 1 && editedEmpDataByPage?.length < 1}
								// loading={popupFlag}
								btnType="secondary"
								className="payroll-plus-newUpload"
								// toolTipClassName="width_100_percentage"
								loading={updateStagingMode === 'save' ? updateStagingLoader : false}
								onClick={() => {
									handleSave(moment(date?.salaryDate).format('MM'), moment(date?.salaryDate).format('YYYY'));
								}}>
								{PAYROLL_CONSTANTS.runPayroll.header.saveText}
							</EdenredButtons>
						</span>
						<span>
							<EdenredButtons
								btnType="primary"
								disabled={!nextHide || buttonDisable || isSalaryExceeds}
								className="payroll-plus-newNext"
								// toolTipClassName="width_100_percentage"								
								loading={handleSaveAndContinueLoader()}
								onClick={handleSaveAndContinue}>
								{PAYROLL_CONSTANTS.runPayroll.header.saveAndContinueText}
							</EdenredButtons>
						</span>
					</div>
				</div>
				{hrOnBoardingFlowCompleted !== true && (
					<div className="dashboard-info-wrapper">
						<div className="dashboard">
							<ImageComponent src={focus_state_icons?.discount} />
							<ETypo medium b1>
								NEW! Get automated calculations and payslips with the new features: Allowances, Additions, and Deductions. Setup now!
							</ETypo>
						</div>
						<span>
							<EdenredButtons
								btnType="secondary"
								children="Get Started"
								className="getStart-btn"
								onClick={() => history.push('/new-hr-features/allowance')}
								rightIcon={<ImageComponent src={not_focus_icons?.arrowRight} />}
							/>
						</span>
					</div>
				)}
				{payrollPlusBanner && hrSetupDone && payrollPlusBannerShow && (
					<div className="payroll-allowance-data-wrapper">
						<div className="payroll-allowance-data">
							<ImageComponent src={focus_state_icons?.deliveredSuccess} />
							<ETypo medium b1>
								Your Payroll table now includes allowances, additions and deductions. Use these columns for a more accurate payroll
								run.
							</ETypo>
						</div>
						<span>
							<EdenredButtons
								btnType="secondary"
								className="cancel-btn"
								children="x"
								onClick={() => {
									dispatch(setupAction.creators.postHrSetupStatus({ payrollPlusBanner: false }));
									setPayrollPlusBannerShow(false);
								}}
							/>
						</span>
					</div>
				)}

				{isEmployeeNotUpload && (
					<div className="payroll-upload-employee-data-wrapper">
						<div className="payroll-upload-employee-data">
							<ImageComponent src={focus_state_icons?.navbarAlert} />
							<ETypo medium b1>
								Upload your employees’ Basic Salary and Allowances details in bulk!
							</ETypo>
						</div>
						<span>
							<EdenredButtons
								btnType="secondary"
								children="Complete Onboarding"
								className="onboarding-btn"
								onClick={() => {
									ClevertapReact.event('V1_1_PayrollTable_CompleteOnb');
									history.push('/new-hr-features/uploadPayrollDetails');
								}}
								rightIcon={<ImageComponent src={not_focus_icons.arrowRight} />}
							/>
						</span>
					</div>
				)}

				<div className="payroll_plus_new_table">
					<PayrollPlusNewTableHeader
						{...{
							payrollTableLoader,
							onChangeHandler,
							handlePagination,
							requestParams,
							handlePayrollPlusFilterSelect,
							handleSelectAll,
							dropDownValueState,
							setIsAllSelected,
							setDropDownValueState,
						}}
					/>
					<div className="table-outer" ref={(el) => (tableRef = el)}>
						{/* <div className="arrow-container" onClick={() => scrollEvent()}>
							<ImageComponent className={!toogleScroll ? 'right-icon left-icon' : 'right-icon'} src={icons.focus_state.RightIcon} />
						</div> */}
						{payrollPlusEmpTableData?.length > 0 ? (
							<Table {...{ columns, data: empData?.tableData, sticky: true, onRowSelectedColor: '#F7F8FA' }} />
						) : (
							<ContentLoader />
						)}
					</div>
					<div className="payroll_plus_pagination">
						<div className="payroll-plus-pagination-count">
							<ETypo light small color="#484B52">
								{empData?.tableData?.length} out of {empCount} Employees
							</ETypo>
						</div>
						<Pagination
							{...{
								currentPage: requestParams?.pageNumber,
								totalCount: empCount || 1,
								pageSize: requestParams?.pageSize,
								onPageChange: (page) => handlePagination(page, 'pageNumber'),
							}}
						/>
					</div>
				</div>
			</Col>
			<WpsNonWpsPopupFunctional {...{ isPopupVisible, setIsPopupVisible }} />
			{/* <Modalcarousel
				{...{ visible: popupNew, handleClose, data: dataObj, handleSlideChange, initialSlide: nextSlide, status: 'payroll' }}></Modalcarousel> */}

			<TransferFundPopUpFunctional />
		</Row>
	);
};

export default PayrollPlusNewPresentational;
