import React from 'react';
import { PAYROLL_CONSTANTS } from '@pages/payroll/constants';
import { ClipLoader } from 'react-spinners';

const TooltipContent = ({
	loading = false,
	total = 0,
	toolTipData = {
		additions: [],
		deductions: [],
		employeeAllowances: [],
		employeeBasicSalary: 0,
		month: '',
	},
	// For error case scenrio using this flag to show error
	error = null,
}) => {
	// If the total was 0 means there is no paid value so showing this text upfront.
	if (total === 0) {
		return PAYROLL_CONSTANTS.table.paidTooltip.notPaid;
	}
	// If we have paid value , while hovering that loading come initially until api call completes.
	if (loading) {
		return <ClipLoader color="#fff" size={20} />;
	}
	// If receive error will return with error message
	if (error) {
		return <p>{error}</p>;
	}

	const { additions, deductions, employeeAllowances, employeeBasicSalary, month } = toolTipData || {};

	// checking null check here so that seperating the values from above tooltip
	const additionsData = additions || [];
	const deductionsData = deductions || [];
	const employeeAllowancesData = employeeAllowances || [];

	function addHeightAndScroll() {
		// Spreading all data so that we can find total length easily.
		const checkTotalData = [...additionsData, ...deductionsData, ...employeeAllowancesData];
		/*
         If the length was > 4 then the tooltip has more data and needs a scroll there , 
        so adding a classname that classname will add height and overflow
        */
		if (checkTotalData.length > 4) {
			return 'tooltip-content-scroll';
		}
		return '';
	}
	// It will return li items based on data
	function listItems(data = []) {
		if (data?.length) {
			return data.map(({ name, value }, index) => (
				<li key={index}>
					{name} - {value || 0}
				</li>
			));
		}
		return null;
	}

	return (
		<div className={`tooltip-content ${addHeightAndScroll()}`}>
			<p data-testid="test-paid-month-text" className="paid-month-text">
				Paid in {month || ''} so far:
			</p>
			<div data-testid="test-paid-data-calculation" className="paid-data-calculation">
				<li>Basic - {employeeBasicSalary || 0}</li>
				{[additionsData, employeeAllowancesData, deductionsData].map((data) => listItems(data))}
			</div>
		</div>
	);
};

export default TooltipContent;
