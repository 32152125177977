import React from 'react';
import { Route, Switch, Redirect, BrowserRouter } from 'react-router-dom';
import SliderPage from '@pages/auth/slider/slider-page';
import MainLayout from '@pages/main-layout/main-layout-page';
import ManageTeam from '@pages/team-permission/manage-team-members/manage-team-page';
import UserRole from '@pages/team-permission/change-users-role/user-role-page';
import ActionApprove from '@pages/team-permission/action-approve/action-approve-page';
import InviteTeamMember from '@pages/team-permission/invite-team-member/invite-team-member-page';
import InviteTeamMemberType from '@pages/team-permission/invite-team-member/components/invite-team-member-type';
import ChooseUserPermissions from '@pages/team-permission/invite-team-member/components/choose-user-permissions';
import HomePage from '@pages/home/home-page';
import CallbackPage from '@sharedComponent/callback';
import UserAction from '@pages/user-action/userAction-page';
import ApprovePage from '@pages/approve-dashboard/approve-page';
import ChooseVerifierPermissions from '@pages/team-permission/invite-team-member/components/choose-verifier-permissions';
import LoginstepFunctional from '@pages/auth/loginstep-new/loginsetup-functional';
import ForgotPassword from '@pages/auth/forgotpassword/forgot-password-page';
import RunPayroll from '@pages/payroll/run-payroll/run-payroll-page';
import RunPayrollFilter from '@pages/payroll/run-payroll/components/run_payroll_filter';
import UploadSpreadsheet from '@pages/payroll/run-payroll/components/upload_spreadsheet';
import SubMenuPage from '@pages/payroll/sub_menu/sub_menu_page';
import PayrollChannel from '@pages/payroll/payroll-channel/payroll-channel-page';
import PayrollHistory from '@pages/payroll/payroll-status/payroll-history';
import CheckRunPayroll from '@pages/payroll/run-payroll/components/check_run_payroll';
import PayrollStatus from '@pages/payroll/payroll-status/components/payroll-status';
import UsefulFiles from '@pages/payroll/useful-files/useful-files-page';
import BankDetails from '@pages/payroll/bank_details/bank-details-page';
import WpsPayroll from '@pages/payroll/payroll-channel/components/wps-payroll';
import NonWpsPayroll from '@pages/payroll/payroll-channel/components/non-wps-payroll';
import RunPayrollNowLater from '@pages/payroll/payroll-channel/components/run-payroll-now-later';
import SubmitPayroll from '@pages/payroll/payroll-channel/components/submit-payroll';
import NextStepsPayroll from '@pages/payroll/payroll-channel/components/next-steps-payroll';
import ProofOfTranfer from '@pages/payroll/proof-of-transfer/proof-of-transfer-page';
import PayrollTimings from '@pages/payroll/payroll-timings/payroll-timings';
import ApproveRequests from '@pages/payroll/approve-requests/approve-requests-page';
import WpsTable from '@pages/payroll/approve-requests/components/wps-table';
import NotPaid from '@pages/payroll/Error-pages/not-paid';
import NotSubmittedDocuments from '@pages/payroll/Error-pages/not-submitted-documents';
import FailedEmployDetail from '@pages/payroll/payroll-status/components/failed_employe_details';
import CreatePassword from '@pages/auth/createpassword/create-password-page';
import DirectPAFPayrollStatus from '@pages/payroll/payroll-status/components/direct-paf-payroll-status';
import ManageEmployee from '@pages/employees/manage-employees/manage-employees-table/manage-employee-page';
import ViewEmployee from '@pages/employees/manage-employees/view-employee/view-employee-page';
import UpdateEmployeesEmiratesId from '@pages/employees/manage-employees/manage-employees-table/components/update-employees-emirates-id';
import EditProfile from '@pages/employees/manage-employees/view-employee/components/edit-profile';
import ApproveEmployeeRequest from '@pages/employees/approve-employee-request/approve-employee-request-page';
import ReplacementCard from '@pages/employees/manage-employees/view-employee/components/replacement-card';
import NextStepEmployee from '@pages/employees/manage-employees/nextstep/next-step-page';
import ReplaceCardNextStep from '@pages/employees/manage-employees/nextstep/components/replace-card-next-step-presentational';
import TransactionDetail from '@pages/employees/manage-employees/view-employee/components/transaction-details';
import NextStepTransaction from '@pages/employees/manage-employees/nextstep/components/transaction-nextStep';
import ChangeBankAccount from '@pages/employees/manage-employees/view-employee/components/change-bank-account';
import RequestStatement from '@pages/employees/manage-employees/view-employee/components/request-statement';
import AddEmployees from '@pages/employees/add-employees/add-employees-page';
import IndividualEmployee from '@pages/employees/add-employees/individual-employee/individual-employee-page';
import UploadEmployeesSpreadsheet from '@pages/employees/add-employees/components/upload-employees-spreadsheet';
import IndividualEmployeeOrderCard from '@pages/employees/add-employees/individual-employee/components/individual-employee-order-card';
import SelectPayrollChannel from '@pages/employees/add-employees/individual-employee/components/select-payroll-channel';
import AddIndividualEmploymentID from '@pages/employees/add-employees/individual-employee/components/add-individual-employment-id';
import AddWPSPersonID from '@pages/employees/add-employees/individual-employee/components/add-wps-person-id-page';
import AddEstablishmentIdToEmployee from '@pages/employees/add-employees/individual-employee/components/add-establishment-id-to-employee';
import AddLabourCardNumber from '@pages/employees/add-employees/individual-employee/components/add-labour-card-number';
import AddEmiratesIdNumber from '@pages/employees/add-employees/individual-employee/components/add-emirates-id-number';
import AddPassportNumber from '@pages/employees/add-employees/individual-employee/components/add-passport-number';
import AddEmployeeIBANNumber from '@pages/employees/add-employees/individual-employee/components/add-employee-iban-number';
import AddEmployeeBankName from '@pages/employees/add-employees/individual-employee/components/add-employee-bank-name';
import IndividualEmployeeNextSteps from '@pages/employees/add-employees/individual-employee/components/individual-employee-next-steps';
import StatementDelivered from '@pages/employees/manage-employees/view-employee/components/statement-delivered';
import EmployeeDocument from '@pages/employees/manage-employees/view-employee/components/employee-document';
import CardRequestDetail from '@pages/employees/approve-employee-request/components/card-request-detail';
import MoreSubMenu from '@pages/more-sub-menu/more-sub-page';
import EmployeesSubMenu from '@pages/employees/employees-sub-menu/employees-sub-page';
import MultipleEmployeesSubmit from '@pages/employees/add-employees/components/multiple-employees-submit';
import MultipleEmployeesNextSteps from '@pages/employees/add-employees/components/multiple-employees-next-steps';
import CardOrderStatus from '@pages/employees/card-order-status/card-order-status-page';
import ViewAllPage from '@pages/video-tutorial/view-all/view-all-page';
import TrackCardOrderStatus from '@pages/employees/card-order-status/components/track-card-order-status';
import ViewCardOrderDetails from '@pages/employees/card-order-status/components/view-card-order-details';
import IbmBankNumber from '@pages/employees/manage-employees/view-employee/components/ibm-bank-number';
import IbmBankName from '@pages/employees/manage-employees/view-employee/components/ibm-bank-name';
import EmployeesUsefulFiles from '@pages/employees/useful-files/useful-files-page';
import ManageEmployeesFilter from '@pages/employees/manage-employees/manage-employees-table/components/manage-employees-filter';
import PreparingStatementHardCopy from '@pages/employees/manage-employees/view-employee/components/preparing-statement-hardCopy';
import PreparingStatementSoftCopy from '@pages/employees/manage-employees/view-employee/components/preparing-statement-softCopy';
import ViewSalaryHistory from '@pages/employees/manage-employees/view-employee/components/view-salary-history';
import DeleteEmployeesInBulk from '@pages/employees/manage-employees/manage-employees-table/components/delete-employees-in-bulk';
import DeleteEmployeesInBulkSubmit from '@pages/employees/manage-employees/manage-employees-table/components/delete-employees-in-bulk-submit';
import ProfileDashboard from '@pages/dashboard/profile/profile-page';
import ChangePassword from '@pages/dashboard/change-password/change-password-page';
import MainDasboard from '@pages/dashboard/main-dashboard/main-dashboard-page';
import TransactionHistory from '@pages/dashboard/transaction-history/transaction-history-page';
import CompanyInformation from '@pages/company/company-information/company-information-page';
import Invoices from '@pages/company/invoices/invoices-page';
import CompanyBranch from '@pages/company/company-information/components/company-branch';
import CompanyAuthority from '@pages/company/company-information/components/company-authority';
import CompanyEstablishmentID from '@pages/company/company-information/components/company-establishment-id';
import CompanyIndustry from '@pages/company/company-information/components/company-industry';
import PleaseUploadSpreadsheet from '@pages/company/company-information/components/please-upload-spreadsheet';
import TradeLicenses from '@pages/company/company-information/components/trade-licenses';
import videoTutorialList from '@pages/video-tutorial/video-list/video-tutorial-page';
import RecheckingFailedEmployee from '@pages/payroll/payroll-status/components/rechecking-failed-employee';
import AddNewSignatory from '@pages/company/company-information/components/add-new-signatory';
import SalaryReports from '@pages/salary-reports';
import SalaryPeremployee from '@pages/salary-reports/salary-per-employee/salary-per-employee-page';
import SalaryHistory from '@pages/salary-reports/salary-history-employee/salary-history-page';
import SalaryMonttwiseReports from '@pages/salary-reports/salary-monthwise-reports/salary-monthWise-page';
import runpayrollnew from '@pages/payroll/run-payroll-new/run-payroll-new';
import PayrollPlusNew from '@pages/payroll-plus-new/index';
import HealthInsurance from '@pages/health-insurance/health-insurance-page';
import Employeesmodule from '@pages/payroll/EmployeesExpirymodule/Employeesmodule';
import Filter from '@pages/payroll/EmployeesExpirymodule/component/filter';
import ApprovedPage from '@pages/payroll/EmployeesExpirymodule/component/ApprovedPage';
import Uploaddocument from '@pages/payroll/EmployeesExpirymodule/component/Upload-document';
import LeaveStatuses from '@pages/Leave-Management/leaves-status/leaveStatus';
import LeavesToApprove from '@pages/Leave-Management/Leave-to-approve/leaves-to-approve';
import RequestLeaves from '@pages/Leave-Management/request-leave/request-leaves';
import EmployeeHistoryFunctional from '@pages/Leave-Management/leaves-status/Component/employeeHistoryFunctional';
import PayrollSetting from '@pages/payroll-plus/payroll-settings';
import CompanySetting from '@pages/Leave-Management/company-settings/company-setting';
import Accouncement from '@pages/payroll-plus/Accouncement/component/announcement-main';
import SuccessfullMessage from '@pages/payroll-plus/Accouncement/component/successfull-presentational';
import ApprovalWorkflow from '@pages/approval-workflow-leave/approval-workflow';
import Teampayroll from '@pages/team-permission/teams-payroll/index';
import Permission from '@pages/team-permission/permission-page/payroll-Permission-Persentational';
import Hrpermission from '@pages/team-permission/permission-page/hrPermissionPersentaional';
// import PayrollSetting from '@pages/payroll-plus/payroll-settings';
// import Accouncement from '@pages/payroll-plus/Accouncement/component/announcement-main';
// import SuccessfullMessage from '@pages/payroll-plus/Accouncement/component/successfull-presentational';

import UnauthorizedAccess from 'src/helpers/UnauthorizedAccess';
import PayrollPermission from '@pages/team-permission/permission-page/payroll-Permission';
import HrPermission from '@pages/team-permission/permission-page/hrPermission';
import BankAccountfunctional from '@pages/cardholder/cardholder/bank-account-functional';
import C3paynumber from '@pages/cardholder/cardholder/c3paynumber';
import banknumber from '@pages/cardholder/cardholder/banknumber';
import HomeMain from '@pages/home-main/home-main';
import PayslipMain from '@pages/payslip/payslip-main';
import UpdateMain from '@pages/employees/upload-employees/update-main';
import { useSelector } from 'react-redux';
import Trails from '@pages/DesignSystem';
import SetUpMain from '@pages/setup-Screen/setup-main';
import SetupDocumentMain from '@pages/setup-document/setup-document-main';
import SetupHrDocumentMain from '@pages/setup-document-hr/setup-hrdocument-main';
import SetupUploadEmployeeList from '@pages/setup-document-hr/components/setupUploadEmployeeList';
import SetUpCompanyMain from '@pages/setup-company-setting/setup-company-setting';
import SetupLeaveUploadFunctional from '@pages/setup-leave-approval/leave-upload-functional';
import LeaveUploadTable from '@pages/setup-leave-approval/leave-upload-table';
import LandingPage from '@pages/landing-page/landing-page';
import SetupLandingPage from '@pages/setup-landing/components/index';
import Main from '@pages/setup-team-permission/main';
import Admissionpermission from '@pages/setup-team-permission/components/admission-permission';
import Permissionassign from '@pages/setup-team-permission/components/permission-assign';
import SetupUploadEmployeePayrollList from '@pages/setup-document/components/setupUploadEmployeePayrollList';
import SetupCreateClaims from '@pages/setup-team-permission/components/setup-create-claims';
import Setupinsurance from '@pages/setup-insurance/components/medical-insurance-functional';
import LandingNewMain from '@pages/new-landing-page';
import BillingScreen from '@pages/new-landing-page/component/billing-screen';
import EmployeeLeaveRequestMain from '@pages/employee-leave-request/employee-request';
import SetupSlider from '@pages/setup-slider/setup-Slider';
import LeaveUpload from '@pages/Leave-Management/leave-upload/leave-upload';
import LeaveApproveTable from '@pages/Leave-Management/leave-upload/leave-upload-table';
import ApproveWorkflowSetup from '@pages/setup-approve-workflow/approve-workflow-setup';
import AddBulkIndividualEmployeeCard from '@pages/employees/add-employees/components/add-bulk-individual-employee-card';
import AddIndividualMain from '@pages/employees/add-individual-employee/add-individual-main';
import AddIndividualContractMain from '@pages/employees/add-individual-contract-detail/add-individual-main';
import AddIndividualEmpDocument from '@pages/employees/add-individual-employee-doc-details/add-individual-emp-doc-main';
import SetupleaveUpload from '@pages/Leave-Management/leave-upload/setup-leave-upload';
import SetupleaveApprovTable from '@pages/Leave-Management/leave-upload/setupleaveApproveTable';
import SetupteamsandPermissions from '@pages/setup-team-permission/setupteamsandPermissions';

import Intro from '@pages/UnEmploymentInsurance/Intro';
import { default as UnEmploymentInsuranceEmployee } from '@pages/UnEmploymentInsurance/Employee';
import InsurancePlan from '@pages/UnEmploymentInsurance/Plan';
import TermsAndConditions from '@pages/UnEmploymentInsurance/TermsAndConditions';

import EwaTermsAndConfitions from '@pages/dashboard/main-dashboard/components/ewa-terms-and-condition';

import Success from '@pages/UnEmploymentInsurance/Success';
import SingleUpdate from '@pages/UnEmploymentInsurance/SingleUpdate';
import BulkUpdate from '@pages/UnEmploymentInsurance/BulkUpdate';

import AddEditLeaveBalanceFunctional from '@pages/Leave-Management/AddEditLeaveBalance/AddEditLeaveBalanceFunctional';
import PreviewUploadedData from '@pages/Leave-Management/AddEditLeaveBalance/PreviewUploadedData';
import UploadDocumentsData from '@pages/payroll/EmployeesExpirymodule/component/UploadDocumentsData/UploadDocumentsData';
import PreviewUploadedDocumentData from '@pages/payroll/EmployeesExpirymodule/component/PreviewUploadedDocumentData/PreviewUploadedDocumentData';
import AllowancePage from '@pages/NewHrModuleSetup/allowance/allowancePage';
import AdditionPage from '@pages/NewHrModuleSetup/addition/additionPage';
import DeductionPage from '@pages/NewHrModuleSetup/deduction/deductionPage';
import InvoiceDetailPage from '@pages/invoice-details/invoice-detail-page';
import invoiceHistory from '@pages/invoice-details/components/invoice-history';
import GroupTable from '@pages/invoice-details/components/group-table';

import UploadPayrollNewDetails from '@pages/NewHrModuleSetup/UploadPayrollDetails';
import { default as HrFeatureAccess } from '@pages/NewHrModuleSetup/HrFeatureAccess/withReducer';
import EmployeeAnnouncement from '@pages/NewHrModuleSetup/Announcements';
import { default as Announcement } from '@pages/NewHrModuleSetup/Announcements/withReducer';
import { default as DocumentsExpiryPage } from '@pages/NewHrModuleSetup/DocumentsExpiry';
import UploadPayrollDocumentation from '@pages/NewHrModuleSetup/UploadPayrollDetails/UploadPayrollDocumentation';
import Allowance from '@pages/NewHrModuleSetup/allowance/Allowance';
import Addition from '@pages/NewHrModuleSetup/addition/Addition';
import Deduction from '@pages/NewHrModuleSetup/deduction/deduction';
import HrFeatureSummary from '@pages/NewHrModuleSetup/HrFeatureAccess/HrFeatureSummary';
// import DocumentSetup from '@pages/NewHrModuleSetup/DocumentsExpiry/DocumentSetup';
import DocumentExpiry from '@pages/NewHrModuleSetup/DocumentsExpiry/DocumentExpiry';
import UploadDocumentPreview from '@pages/NewHrModuleSetup/DocumentsExpiry/UploadDocumentPreview';
import DocumentSetupPage from '@pages/NewHrModuleSetup/DocumentsExpiry/DocumentSetup';
import DepartmentsPage from '@pages/NewHrModuleSetup/Leaves/Departments/DepartmentsPage';
import UploadLeavePage from '@pages/NewHrModuleSetup/Leaves/UploadLeaveDetails/UploadLeavePage';
import InviteEmployeesPage from '@pages/NewHrModuleSetup/Leaves/InviteEmployees/InviteEmployeesPage';
import SetupApprovalWorkflowPage from '@pages/NewHrModuleSetup/Leaves/SetupApprovalWorkflow/SetupApprovalWorkflowPage';
import Departments from '@pages/NewHrModuleSetup/Leaves/Departments/Departments';
import InviteEmployees from '@pages/NewHrModuleSetup/Leaves/InviteEmployees/InviteEmployees';
import SetupApprovalWorkflow from '@pages/NewHrModuleSetup/Leaves/SetupApprovalWorkflow/SetupApprovalWorkflow';
import UploadLeave from '@pages/NewHrModuleSetup/Leaves/UploadLeaveDetails/UploadLeave';
import SetupUploadDocumentPreview from '@pages/NewHrModuleSetup/DocumentsExpiry/SetupUploadDocumentPreview';

import PayrollPlusNewHrFeatureFunction from '@pages/payroll-plus-new/components/PayrollPlusNewHrFeatureFunction';
import UploadSpreadsheetPlus from '@pages/payroll/run-payroll/components/UploadSpreadsheetPlus';
import UploadSpreadsheetSetup from '@pages/payroll/run-payroll/components/UploadSpreadsheetSetup';
import MFA from '@pages/auth/mfa';
import { SITEMAP } from './sitemap';
import EdenredTC from '@pages/TermsAndConditions';
import ViewInvoices from '@pages/invoice-details/components/ViewInvoices';
import CardsReport from '@pages/salary-reports/CardReport';
import { VerificationOnDashboard } from '@pages/employees/EmployeeVerification';
import PendingAlertsPage from '@pages/dashboard/alerts/PendingAlertsPage';
import HelpPage from '@pages/help';

const MainRoutes = () => {
	return (
		<BrowserRouter getUserConfirmation={() => { }}>
			<Switch>
				<Route path={SITEMAP.auth.mfa} component={MFA} />
				<Route path={SITEMAP.termsAndConditions.index} component={EdenredTC} />
				{/* <Route exact path="/filter" component={Filter} /> */}
				<Route exact path="/user_role" component={UserRole} />
				<Route path="/approve-workflow-setup" component={ApproveWorkflowSetup} exact />
				<Route path="/setup" component={SetUpMain} exact />
				<Route path="/slidersetup" component={SetupSlider} exact />
				<Route path="/setupmedicalinsurance" component={Setupinsurance} exact />
				<Route path="/setup-landingpage" component={SetupLandingPage} exact />
				<Route path="/setupDocument" component={SetupDocumentMain} exact />
				<Route path="/setupHrDocument" component={SetupHrDocumentMain} exact />
				<Route path="/setupHrEmployeeList" component={SetupUploadEmployeeList} exact />
				<Route path="/setupHrEmployeepayrollList" component={SetupUploadEmployeePayrollList} exact />
				<Route exact path="/company-setup" component={SetUpCompanyMain} />
				<Route exact path="/leave-approval-table-setup" component={LeaveUploadTable} />
				<Route path="/permissionassign/:username" component={Permissionassign} />
				<Route path="/admissionpermission" component={Admissionpermission} />
				<Route path="/setupnewpermission" component={SetupCreateClaims} />
				{/* <Route path="/teamspermission" component={Main} /> */}
				<Route path="/teamspermission" component={SetupteamsandPermissions} />
				<Route path="/landingpage" component={LandingPage} />
				<Route exact path="/forgot" component={ForgotPassword} />
				<Route exact path={SITEMAP.user.slider} component={SliderPage} />
				<Route exact path="/user_action" component={UserAction} />
				<Route exact path={SITEMAP.user.approvePage} component={ApprovePage} />
				<Route exact path="/create-password" component={CreatePassword} />
				<Route exact path="/login-step" component={LoginstepFunctional} />
				<Route exact path="/signin-oidc" component={CallbackPage} />
				<Route exact path="/leave-approval-setup" component={SetupleaveUpload} />
				<Route path="/setup/leave/upload-table" component={SetupleaveApprovTable} exact />
				{/* <Route path="/setup/leave/upload-table" component={LeaveApproveTable} exact /> */}
				<Route path="/new-hr-features/uploadPayrollDetails" component={UploadPayrollNewDetails} />
				<Route path="/new-hr-features/allowance" component={AllowancePage} />
				<Route path="/new-hr-features/addition" component={AdditionPage} />
				<Route path="/new-hr-features/deduction" component={DeductionPage} />
				<Route path="/new-hr-features/summary" component={HrFeatureAccess} />
				<Route path="/new-hr-features/setupAnnouncement" component={EmployeeAnnouncement} />
				<Route path="/new-hr-features/documentsExpiry" component={DocumentsExpiryPage} />
				<Route path="/new-hr-features/departments" component={DepartmentsPage} />
				<Route path="/new-hr-features/upload-leave" component={UploadLeavePage} />
				<Route path="/new-hr-features/setup-approval-workflow" component={SetupApprovalWorkflowPage} />
				<Route path="/new-hr-features/invite-employees" component={InviteEmployeesPage} />
				<Route path="/new-hr-features/preview-upload-document" component={SetupUploadDocumentPreview} exact />

				<Route path="/ewa-terms-and-conditions" component={EwaTermsAndConfitions} exact />
				<Route path="/" component={HomePage} />
			</Switch>
		</BrowserRouter>
	);
};

const MenuRoutes = () => {
	const emp_Id = useSelector((state) => state?.sharedStates?.currentUser?.user?.employeeId);
	const isId = emp_Id ? emp_Id : '';
	return (
		<>
			<Switch>
				<Route path={SITEMAP.employees.verification.dashboard.index} component={VerificationOnDashboard} />
				<Route path="/payroll/run/channel/nonwps" component={NonWpsPayroll} exact />
				<Route path="/payroll/run/channel/wps" component={WpsPayroll} exact />
				<Route path="/payroll/run/channel/now_or_later" component={RunPayrollNowLater} exact />
				<Route path="/payroll/run/channel/submit_payroll" component={SubmitPayroll} exact />
				<Route path="/payroll/run/channel/next_steps" component={NextStepsPayroll} exact />

				<Route path="/manage_team" component={ManageTeam} />
				<Route path="/user_role/:username" component={UserRole} exact />

				<Route path="/action_approve/:username/:roleid" component={ActionApprove} exact />
				<Route path="/payroll/run/channel" component={PayrollChannel} exact />
				<Route path="/payroll/history" component={PayrollHistory} exact />
				<Route path="/payroll/rechecking_failed_employee/:uploadId" component={RecheckingFailedEmployee} />
				<Route path="/payroll/status/:uploadId" component={PayrollStatus} exact />
				<Route path="/payroll/check_how_to_run" component={CheckRunPayroll} exact />
				<Route path="/payroll/proof_of_transfer" component={ProofOfTranfer} exact />
				<Route path="/invite_team_member" component={InviteTeamMember} exact />
				<Route path="/invite_team_member_type" component={InviteTeamMemberType} exact />
				<Route path="/choose_user_permission" component={ChooseUserPermissions} exact />
				<Route path="/choose_verifier_permission" component={ChooseVerifierPermissions} exact />
				<Route path="/run-payroll" component={RunPayroll} exact />
				<Route path="/filter" component={RunPayrollFilter} exact />
				<Route path="/upload/salary/spreadsheet_or_sif" component={UploadSpreadsheet} exact />
				<Route path="/SubMenuPage" component={SubMenuPage} exact />
				<Route path="/payroll/status/direct-paf/status/:uploadId" component={DirectPAFPayrollStatus} exact />
				<Route path="/payroll/useful_files" component={UsefulFiles} exact />
				<Route path="/payroll/approve_requests" component={ApproveRequests} exact />
				<Route path="/payroll/timings" component={PayrollTimings} exact />
				<Route path="/payroll/bank_details" component={BankDetails} exact />
				<Route path="/payroll/failedDetail/:uploadId" component={FailedEmployDetail} exact />
				<Route path="/payroll/run/channel/wps" component={WpsPayroll} exact />
				<Route path="/payroll/run/channel/now_or_later" component={RunPayrollNowLater} exact />
				<Route path="/payroll/run/channel/submit_payroll" component={SubmitPayroll} exact />
				<Route path="/payroll/check_how_to_run" component={CheckRunPayroll} exact />
				<Route path="/payroll/wps_table" component={WpsTable} />

				<Route path="/payroll/rechecking_failed_employee" component={RecheckingFailedEmployee} />

				<Route path="/error/not_submitted" component={NotSubmittedDocuments} exact />
				<Route path="/error/not_paid" component={NotPaid} exact />
				<Route path="/employees/manage_employee" component={ManageEmployee} exact />
				<Route path="/employees/manage_employee/filter" component={ManageEmployeesFilter} exact />
				<Route path="/manange/employees/bulk/delete" component={DeleteEmployeesInBulk} exact />
				<Route path="/manange/employees/bulk/delete/sumbit" component={DeleteEmployeesInBulkSubmit} exact />
				<Route path="/employees/viewemployee" component={ViewEmployee} exact />
				<Route path="/employees/update_emirate" component={UpdateEmployeesEmiratesId} exact />
				<Route path="/employees/edit_profile" component={EditProfile} exact />
				<Route path="/employees/approve_employee_request" component={ApproveEmployeeRequest} exact />
				<Route path="/employees/replacement_card" component={ReplacementCard} exact />
				<Route path="/employees/nextEmployee_step" component={NextStepEmployee} exact />
				<Route path="/employees/replace_card_next_step" component={ReplaceCardNextStep} exact />
				<Route path="/employees/transaction-detail" component={TransactionDetail} exact />
				<Route path="/employees/nextTransaction_step" component={NextStepTransaction} exact />
				<Route path="/employees/changeBankAccount" component={ChangeBankAccount} exact />
				<Route path="/employees/requestStatement" component={RequestStatement} exact />
				<Route path="/employees/statementDelivered" component={StatementDelivered} exact />

				{/* <Route path="/unemployment-insurance/intro" component={Intro} exact />
				<Route path="/unemployment-insurance/employees" component={Employees} exact />
				<Route path="/unemployment-insurance/insurance-plan" component={InsurancePlan} exact />
				<Route path="/unemployment-insurance/terms-and-conditions" component={TermsAndConditions} exact />
				<Route path="/unemployment-insurance/submission-success" component={SubmissionSuccess} exact /> */}

				{/* // newly added page */}
				<Route path="/videoTutorial" component={videoTutorialList} exact />
				<Route path="/view_all" component={ViewAllPage} exact />
				{/* added page */}
				<Route path="/employees/preparingStatement-HardCopy" component={PreparingStatementHardCopy} exact />
				<Route path="/employees/employee-document" component={EmployeeDocument} exact />
				<Route path="/employees/card-request-detail/:uploadId/:employeeId/:massCorpDelUploadId" component={CardRequestDetail} exact />
				<Route path="/employees/add" component={AddEmployees} exact />
				<Route path="/employees/add/individual/employee" component={IndividualEmployee} exact />
				<Route path="/employees/add/individual/employee/order/card" component={IndividualEmployeeOrderCard} exact />
				<Route path="/employees/add/individual/employee/select/channel/type" component={SelectPayrollChannel} exact />
				<Route path="/employees/add/individual/employee/employment/id" component={AddIndividualEmploymentID} exact />
				<Route path="/employees/add/individual/employee/wps/personid" component={AddWPSPersonID} exact />
				<Route path="/employees/add/individual/employee/establishmentid" component={AddEstablishmentIdToEmployee} exact />
				<Route path="/employees/add/individual/employee/labourcardnumber" component={AddLabourCardNumber} exact />
				<Route path="/employees/add/individual/employee/emiratesidnumber" component={AddEmiratesIdNumber} exact />
				<Route path="/employees/add/individual/employee/passportnumber" component={AddPassportNumber} exact />
				<Route path="/employees/add/individual/employee/iban/number" component={AddEmployeeIBANNumber} exact />
				<Route path="/employees/add/individual/employee/bankname" component={AddEmployeeBankName} exact />
				<Route path="/employees/add/individual/employee/details/submit/nextsteps" component={IndividualEmployeeNextSteps} exact />
				<Route path="/employees/add/multiple/employees/upload" component={UploadEmployeesSpreadsheet} exact />
				<Route path="/more-sub-menu" component={MoreSubMenu} exact />
				<Route path="/employees/sub-menu" component={EmployeesSubMenu} exact />
				<Route path="/employees/add/multiple/employees/submit" component={MultipleEmployeesSubmit} exact />
				<Route path="/employees/add/multiple/employees/submit/nextsteps" component={MultipleEmployeesNextSteps} exact />
				<Route path="/employees/card/order/status" component={CardOrderStatus} exact />
				<Route path="/employees/card/order/track/status" component={TrackCardOrderStatus} exact />
				<Route path="/employees/card/order/view/details" component={ViewCardOrderDetails} exact />
				<Route path="/employees/ibm/number" component={IbmBankNumber} />
				<Route path="/employees/ibm/name" component={IbmBankName} />
				<Route path="/employees/useful/files" component={EmployeesUsefulFiles} exact />
				<Route path="/run-payroll-new" component={runpayrollnew} exact />
				<Route path="/payroll-plus" component={PayrollPlusNew} exact />

				<Route path="/employees/PreparingStatement-softcopy" component={PreparingStatementSoftCopy} />
				<Route path="/employees/view-salary-history" component={ViewSalaryHistory} />
				<Route path="/dashboard/profile" component={isId !== '' ? ViewEmployee : ProfileDashboard} />
				<Route path="/dashboard/change-password" component={ChangePassword} />
				<Route path={SITEMAP.dashboard.pendingAlerts} component={PendingAlertsPage} />
				<Route path="/dashboard/transaction-history" component={TransactionHistory} exact />
				<Route path="/company/information" component={CompanyInformation} exact />
				<Route path="/company/invoices" component={Invoices} exact />
				<Route path="/company/branch" component={CompanyBranch} exact />
				<Route path="/company/authority" component={CompanyAuthority} exact />
				<Route path="/company/establishment-id" component={CompanyEstablishmentID} exact />
				<Route path="/company/industry" component={CompanyIndustry} exact />
				<Route path="/company/upload-spreadsheet" component={PleaseUploadSpreadsheet} exact />
				<Route path="/company/trade/licenses" component={TradeLicenses} exact />
				<Route path="/company/addnew/signatory" component={AddNewSignatory} exact />
				<Route path={SITEMAP.help.index} component={HelpPage} />
				<Route path={SITEMAP.payroll.salaryReports.index} component={SalaryReports} exact />
				<Route path={SITEMAP.payroll.salaryReports.perEmployee} component={SalaryPeremployee} exact />
				<Route path={SITEMAP.payroll.salaryReports.history} component={SalaryHistory} exact />
				<Route path={SITEMAP.payroll.salaryReports.monthWiseReports} component={SalaryMonttwiseReports} exact />
				<Route path={SITEMAP.payroll.salaryReports.cards} component={CardsReport} exact />
				<Route path="/healthinsurance" component={HealthInsurance} exact />
				<Route path="/hrdocuments" component={Employeesmodule} exact />
				<Route path="/hrdocuments/uploaddocument" component={Uploaddocument} exact />
				<Route path="/hrdocuments/uploaddocument/approved" component={ApprovedPage} exact />
				<Route path="/leaves/leaves_to_approve" component={LeavesToApprove} exact />
				<Route path="/leaves/leaves_statuses" component={LeaveStatuses} exact />
				<Route path="/leaves/request_leave" component={RequestLeaves} exact />
				<Route path="/employee/leave" component={EmployeeHistoryFunctional} exact />
				<Route path="/payroll_plus/payroll_setting" component={PayrollSetting} exact />
				<Route path="/company/company-setting" component={CompanySetting} exact />
				<Route path="/announcement" component={Accouncement} exact />
				<Route path="/announcement/successfull" component={SuccessfullMessage} exact />
				<Route path="/" component={MainDasboard} exact />
				<Route path="/home" component={HomeMain} exact />
				<Route path="/approval-workflow-leave" component={ApprovalWorkflow} exact />
				<Route path="/team-payroll" component={Teampayroll} exact />
				<Route path="/permission/user" component={PayrollPermission} exact />
				<Route path="/hrpermission/user" component={HrPermission} exact />
				<Route path="/cardholder" component={BankAccountfunctional} exact />
				<Route path="/cardholder/c3paynumber" component={C3paynumber} exact />
				<Route path="/cardholder/banknumber" component={banknumber} exact />
				<Route path="/payslip" component={PayslipMain} exact />
				<Route path="/design-system" component={Trails} exact />
				<Route path="/employee/update" component={UpdateMain} exact />
				{/* <Route path="/unauthorizedaccess" component={UnauthorizedAccess} exact /> */}
				<Route exact path="/landingNew" component={LandingNewMain} />
				<Route path="/landingNew/billing" component={BillingScreen} exact />
				<Route path="/employee/request_leave" component={EmployeeLeaveRequestMain} exact />
				<Route path="/leave/upload" component={LeaveUpload} exact />
				<Route path="/leave/upload-table" component={LeaveApproveTable} exact />
				<Route path="/employee/add-individual-employee" component={AddIndividualMain} exact />
				<Route path={SITEMAP.employees.add} component={AddBulkIndividualEmployeeCard} exact />
				<Route path="/employee/add-individual-contract" component={AddIndividualContractMain} exact />
				<Route path="/employee/add-individual-document" component={AddIndividualEmpDocument} exact />

				<Route path="/un-employment-insurance/intro" component={Intro} exact />
				<Route path="/un-employment-insurance/employee" component={UnEmploymentInsuranceEmployee} exact />
				<Route path="/un-employment-insurance/plan" component={InsurancePlan} exact />
				<Route path="/un-employment-insurance/terms-and-conditions" component={TermsAndConditions} exact />
				<Route path="/un-employment-insurance/success" component={Success} exact />
				<Route path="/un-employment-insurance/single-update" component={SingleUpdate} exact />
				<Route path="/un-employment-insurance/bulk-update" component={BulkUpdate} exact />
				<Route exact path="/add-edit-leave-balance" component={AddEditLeaveBalanceFunctional} />
				<Route exact path="/add-edit-leave-balance/preview-table" component={PreviewUploadedData} />

				<Route path="/documents/documents-upload" component={UploadDocumentsData} exact />
				<Route path="/documents/preview-uploaded-document-data-upload" component={PreviewUploadedDocumentData} exact />
				<Route path="/new-hr-features/announcement" component={Announcement} exact />
				<Route path="/hr-features/summary" component={HrFeatureSummary} />
				<Route path="/hr-features/allowance" component={Allowance} />
				<Route path="/hr-features/addition" component={Addition} />
				<Route path="/hr-features/deduction" component={Deduction} />
				<Route path="/documents/document-setup" component={DocumentSetupPage} />
				<Route path="/documents/documents-expiry" component={DocumentExpiry} />
				<Route path="/documents/preview-upload-document" component={UploadDocumentPreview} exact />
				<Route path="/leave/departments" component={Departments} exact />
				<Route path="/leave/upload-leave" component={UploadLeave} exact />
				<Route path="/leave/setup-approval-workflow" component={SetupApprovalWorkflow} exact />
				<Route path="/leave/invite-employees" component={InviteEmployees} exact />
				<Route path="/payroll-plus/preview-uploaded-document-data" component={PayrollPlusNewHrFeatureFunction} exact />
				<Route path="/uploadSpreadsheetPlus" component={UploadSpreadsheetPlus} exact />
				<Route path="/uploadSpreadsheetSetup" component={UploadSpreadsheetSetup} exact />

				<Route path={SITEMAP.invoice.index} component={InvoiceDetailPage} exact />
				<Route path={SITEMAP.invoice.history} component={invoiceHistory} exact />
				<Route path={SITEMAP.invoice.groupDetails} component={GroupTable} exact />
				<Route path={SITEMAP.invoice.viewAll} component={ViewInvoices} exact />
			</Switch>
		</>
	);
};

export { MainRoutes, MenuRoutes };
