import React, { useState } from 'react';
import OptionsContent from './OptionsContent';

const ContentManager = ({
	data,
	handleDropdown,
	icon,
	dropDownValue,
	dropDownSubValue,
	showIcon,
	dateFormat,
	showOptionStatus,
	justifyOption,
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const handleToggle = () => setIsOpen(!isOpen);
	return (
		<div style={{ display: 'flex', alignItems: 'center', paddingLeft: !showIcon ? '8px' : '0px' }}>
			<OptionsContent
				{...{ data, handleDropdown, handleToggle, dropDownValue, dropDownSubValue, icon, dateFormat, showOptionStatus, justifyOption }}
			/>
			{isOpen && (
				<>
					{data?.subMenu?.map((item) => (
						<OptionsContent
							{...{
								data: item,
								handleDropdown,
								handleToggle,
								mainData: { [dropDownValue]: data?.[dropDownValue], [dropDownSubValue]: data?.[dropDownSubValue] },
								isOpen: isOpen,
								showOptionStatus,
								justifyOption,
							}}
						/>
					))}
				</>
			)}
		</div>
	);
};

export default ContentManager;
