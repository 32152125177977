import React from 'react';
import ImageComponent from '@sharedComponent/image-component';

const VideoContent = ({ content }) => {
	function showContent() {
		if (content?.videoSource) {
			return (
				<video className="video-container" autoPlay controls>
					<source src={content?.videoSource} type="video/mp4" />
				</video>
			);
		} else if (content?.imageSource) {
			return <ImageComponent className="intro-image-container" src={content?.imageSource} alt={content?.alt || ''} />;
		}
		return null;
	}
	return <section className="intro-content-video-container">{showContent()}</section>;
};

export default VideoContent;
