import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { icons } from '@assets/icons';
import { getFormatedNumber } from '../group-table';
import ETypo from '@pages/DesignSystem/Typo';
import { callCleverTapEvent } from '@helpers';
import { CLEVER_TAP_EVENTS_CONSTANTS } from '@constants/cleverTap';
import actions from '../../invoiceDetailAction';
import { SITEMAP } from 'src/routes/sitemap';
import ImageComponent from '@sharedComponent/image-component';
import { INVOICE_LEFT_INFO_SECTION } from '@pages/invoice-details/constants';
import './invoiceLeftSection.scss';

export default function InvoiceLeftSection({ invoiceInfo, getInvoiceInfoLoading, invoiceStatementDownloadLoading }) {
	const dispatch = useDispatch();

	function downloadStatementOfAccounts() {
		callCleverTapEvent(CLEVER_TAP_EVENTS_CONSTANTS.invoice.invoice_soa);
		dispatch(actions.creators.downloadCompanyStatementStart());
	}
	return (
		<section className="invoice_left_section" data-testid="test-invoice-left-section">
			<div className="pending_invoice_amount_container">
				<span className="pending_invoice_amount_loader">
					{getInvoiceInfoLoading ? (
						<div className={`loader secondary_loader`} />
					) : (
						<ImageComponent src={icons.focus_state.InvoiceMoney} alt="mony-icon" />
					)}
				</span>

				{invoiceInfo?.isGroup ? (
					<>
						<small style={{ paddingTop: '8px', color: '#737780', fontSize: '12px', fontWeight: 700 }}>Pending Amount for Company</small>
						<ETypo bold h2 style={{ padding: '8px 0 16px' }}>
							AED {invoiceInfo?.pendingInvoiceAmount ? getFormatedNumber(invoiceInfo?.pendingInvoiceAmount) : '0.00'}
						</ETypo>
						<small style={{ paddingTop: '8px', color: '#737780', fontSize: '12px', fontWeight: 700 }}>Pending Amount for Group</small>
						<ETypo bold h2 style={{ padding: '8px 0 8px' }}>
							AED {invoiceInfo?.pendingInvoiceAmountForGroup ? getFormatedNumber(invoiceInfo?.pendingInvoiceAmountForGroup) : '0.00'}
						</ETypo>
						{/* As discused with client we hiding the bellow text
                                            https://dev.azure.com/edenred-eae/B2BSquad/_workitems/edit/17223 */}
						{/* <small style={{ paddingTop: '8px', color: '#484B52', fontSize: '10px', fontWeight: 700 }}>
                                                {`AED ${getFormatedNumber(invoiceInfo?.pendingInvoiceAmount) || 0.0} (Company) + AED ${
                                                    getFormatedNumber(invoiceInfo?.pendingInvoiceAmountForGroup) || 0.0
                                                } (Group)`}
                                            </small> */}
					</>
				) : (
					<>
						<small style={{ paddingTop: '8px', color: '#737780', fontSize: '12px', fontWeight: 700 }}>Pending Invoice Amount</small>
						<ETypo bold h2 style={{ display: 'flex', alignItems: 'center' }}>
							AED{' '}
							{getInvoiceInfoLoading ? (
								<div style={{ marginLeft: '5px', height: '14px', width: '14px' }} className={`loader secondary_loader`} />
							) : invoiceInfo?.pendingInvoiceAmount ? (
								getFormatedNumber(invoiceInfo?.pendingInvoiceAmount)
							) : (
								'0.00'
							)}
						</ETypo>
					</>
				)}
			</div>
			<div className="invoice_options_container" data-testid="test-invoice-options">
				<Link to={SITEMAP.invoice.viewAll} className="invoice-btn">
					<ImageComponent src={icons.focus_state.InvoiceFile} alt="Payment History" />
					<ETypo medium b2 color="#484B52" style={{ width: 'calc(100% - 48px)', padding: '0 8px' }}>
						{INVOICE_LEFT_INFO_SECTION.viewAllInvoices}
					</ETypo>
					<span style={{ width: '16px', height: '16px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
						<ImageComponent src={icons.focus_state.InvoiceRightArrow} alt="Payment History arrow" />
					</span>
				</Link>
				<div className={`invoice-btn ${getInvoiceInfoLoading ? 'inavtive' : ''}`} onClick={downloadStatementOfAccounts}>
					<span
						style={{
							height: '32px',
							width: '32px',
							backgroundColor: '#FFFFFF',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}>
						{invoiceStatementDownloadLoading ? (
							<div className={`loader secondary_loader`} />
						) : (
							<ImageComponent src={icons.focus_state.InvoiceDownloadArrow} alt="Statement download" />
						)}
					</span>
					<ETypo medium b2 color="#484B52" style={{ width: 'calc(100% - 56px)', paddingLeft: '8px' }}>
						{INVOICE_LEFT_INFO_SECTION.statementOfAccounts}
					</ETypo>
				</div>
				<Link to={SITEMAP.invoice.history} className="invoice-btn">
					<span
						style={{
							height: '32px',
							width: '32px',
							backgroundColor: '#FFFFFF',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}>
						<ImageComponent src={icons.focus_state.InvoicePaymentHistory} alt="Payment History" />
					</span>
					<ETypo medium b2 color="#484B52" style={{ width: 'calc(100% - 48px)', padding: '0 8px' }}>
						{INVOICE_LEFT_INFO_SECTION.paymentHistory}
					</ETypo>
					<span style={{ width: '16px', height: '16px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
						<ImageComponent src={icons.focus_state.InvoiceRightArrow} alt="Payment History arrow" />
					</span>
				</Link>
			</div>
			<div className="highlite-text time-saving-tip">
				<div style={{ height: 'calc(24px + 16px)', display: 'flex', alignItems: 'center', paddingBottom: '16px' }}>
					<ImageComponent src={icons.focus_state.InvoiceWeather} alt="Time Saving Tip" />
					<ETypo medium b2 color="#00344E" style={{ padding: '0 8px' }}>
						{INVOICE_LEFT_INFO_SECTION.timeSaverCard.label}
					</ETypo>
				</div>
				<ETypo medium b2 color="#484B52" style={{ display: 'block' }}>
					{INVOICE_LEFT_INFO_SECTION.timeSaverCard.description}
				</ETypo>
			</div>
		</section>
	);
}
