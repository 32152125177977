import React from 'react';
import ImageComponent from '@sharedComponent/image-component';
import './flagImage.scss';

const FlagImage = ({ flagCode = '', alt = '', className = '' }) => {
	// Using the cdn link of jsdeliver using a flag icons with the help of flag code
	// Refer https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.1/flags/
	return (
		<ImageComponent
			className={`flag-image ${className}`}
			alt={alt || flagCode}
			src={`https://cdn.jsdelivr.net/gh/lipis/flag-icons/flags/4x3/${flagCode?.toLowerCase()}.svg`}
		/>
	);
};

export default FlagImage;
