import React from 'react';
import { Col, Row } from 'antd';
import { icons } from '@assets/icons';
import { images } from '@assets/img';
import Pagination from '@pages/DesignSystem/Table/Pagination';
import Table from '@pages/DesignSystem/Table/Table';
import ETypo from '@pages/DesignSystem/Typo';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import ImageComponent from '@sharedComponent/image-component';
import { useHistory } from 'react-router-dom';
import Chips from '@pages/DesignSystem/Chips';
import './viewInvoices.scss';

const ViewInvoices = () => {
	const history = useHistory();

	const showStatus = (type) => {
		if (type === 'paid') {
			return <Chips color="#CCECE6">{type}</Chips>;
		} else if (type === 'pending') {
			return <Chips color="#FFBECB">{type}</Chips>;
		} else {
			return <Chips>NA</Chips>;
		}
	};

	const columns = [
		{
			sorter: (a, b) => a.dateOfInvoice.localeCompare(b.dateOfInvoice),
			key: 'dateOfInvoice',
			title: 'Date of Invoice',
			width: '30%',
			dataIndex: 'dateOfInvoice',
			sort: true,
			render: (val) => {
				return <p className="table-cell">{val}</p>;
			},
		},
		{
			sorter: (a, b) => a.status.localeCompare(b.status),
			key: 'status',
			width: '30%',
			title: 'Status',
			dataIndex: 'status',
			sort: true,
			render: (val) => {
				return <p className="table-cell status-chip">{showStatus(val)}</p>;
			},
		},
		{
			key: 'download',
			title: '',
			dataIndex: 'download',
			sort: true,
			render: (val) => {
				return (
					<p className="table-cell">
						<EdenredButtons
							className="download-invoice-btn"
							btnType="secondary"
							leftIcon={<ImageComponent src={icons.focus_state.downloadAttachement} />}>
							Download invoice
						</EdenredButtons>
					</p>
				);
			},
		},
	];
	const data = [
		// {
		// 	id: '',
		// 	dateOfInvoice: 'Jan04',
		// 	status: 'paid',
		// },
		// {
		// 	id: '',
		// 	dateOfInvoice: 'Jan04',
		// 	status: 'pending',
		// },
		// {
		// 	id: '',
		// 	dateOfInvoice: 'Jan08',
		// 	status: '',
		// },
	];
	const goBack = () => () => {
		history.goBack();
	};
	return (
		<div className="view-all-invoices-container">
			<Row>
				<Col data-testid="test-view-all-invoices-header" className="view-all-invoices-header">
					<ETypo bold h2>
						All Invoices
					</ETypo>
					<EdenredButtons
						onClick={goBack()}
						className="go-back-btn"
						btnType="secondary"
						leftIcon={<ImageComponent src={images.leftsarrow} />}>
						Back
					</EdenredButtons>
				</Col>
			</Row>
			<Row className="view-all-invoices-table" style={{ rowGap: '10px' }}>
				<Table columns={columns} data={data} />
				<div className="table_pagination_container">
					<div className="pagination_count">
						<ETypo light small color="#484B52">
							{data?.length} out of {undefined || 0} Employees
						</ETypo>
					</div>
					<Pagination
						{...{
							currentPage: 1,
							totalCount: 10,
							pageSize: 10,
							className: 'table_pagination',
							onPageChange: (page) => {},
						}}
					/>
				</div>
			</Row>
		</div>
	);
};

export default ViewInvoices;
