import axios from 'axios';
import { APP_VARIABLES, DOMAIN_URL } from '@constants/app-constants';
import { getLocalvariable } from '@helpers';

export const GET_PROFILE = 'GET_PROFILE';
export const SET_LOGIN_STEP = 'SET_LOGIN_STEP';
export const SET_CLAIMS = 'SET_CLAIMS';
export const CREATE_PASSWORD = 'CREATE_PASSWORD';
export const GET_TEMPLATES = 'GET_TEMPLATES';

export default class SharedServices {
	services(type, params, data) {
		const accesstoken = JSON.parse(localStorage.getItem(APP_VARIABLES.ACCESS_TOKEN));
		switch (type) {
			case GET_PROFILE:
				return axios.get(`${DOMAIN_URL}/Users/me`);
			case GET_TEMPLATES:
				return axios.get(`${DOMAIN_URL}/FileTemplate/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}`);
			case SET_LOGIN_STEP:
				return axios.put(`${DOMAIN_URL}/OnBoarding/me/actions/loginstep/${params.step}`);
			case SET_CLAIMS:
				return axios.post(
					`${DOMAIN_URL}/Users/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/${(accesstoken && accesstoken.username) || null
					}/actions/claims`,
					data
				);
			case CREATE_PASSWORD:
				return axios.post(`${DOMAIN_URL}/Users/me/actions/new-user-change-password`, data);
			default:
				break;
		}
	}
}
