// Our bank details page in payroll
const bankDetailsCommonData = [
    { title: 'Bank Name', value: 'Ras Al Khaimah Bank (RAK BANK)' },
    { title: 'Bank Branch', value: 'Umm Hurair, Dubai' },
    { title: 'Account Name', value: 'Edenred Prepaid Cards Management Services LLC' },
    { title: 'Swift Code', value: 'NRAKAEAK' },
]
const transferSalaryFunds = [
    { title: 'Account Number', value: '0332545791001' },
    { title: 'IBAN Number', value: 'AE830400000332545791001' },
    { title: 'Routing Code', value: '740810000' },
]

const payInvoices = [
    { title: 'Account Number', value: '0332545791003' },
    { title: 'IBAN Number', value: 'AE290400000332545791003' },
    { title: 'Routing Code', value: 'N/A' },
]


export const PAYROLL_CONSTANTS = Object.freeze({
    runPayroll: {
        header: {
            title: 'Run Payroll for',
            payrollAmountText: 'Payroll Amount:',
            saveText: 'Save',
            saveAndContinueText: 'Save & Continue',
            nextText: 'Next',
        }
    },
    table: {
        paidTooltip: {
            notPaid: 'You haven’t paid anything yet for this month',
            errorMsg: 'Something went wrong, Pls try again'
        }
    },
    employeeSalaryStatus: {
        waitingForApprove: {
            status: 'Waiting for approver',
            message: 'Payroll already submitted & is being processed'
        },
        approved: {
            status: 'Approved',
            message: 'Payroll already processed for this month, no further changes can be made'
        },
        salaryProcessed: {
            status: 'Salary Processed',
            message: 'Payroll already processed for this month, no further changes can be made'
        },
        totalSalaryZero: {
            status: 'totalSalaryZero',
            message: 'The total salary cannot be 0. Check all the values'
        },
        totalSalaryNegative: {
            status: 'totalSalaryNegative',
            message: 'The total salary cannot be negative. Check all the values'
        },
    },
    employeeAccountType: {
        bank: 'Bank Accounts',
        card: 'C3Pay Cardholders'
    },
    salaryLimit: 50000,
    // Our Bank details page
    bankDetails: {
        title: 'Bank Details',
        description: 'The account you transfer salary funds to is DIFFERENT from the account which you’ll use to pay your invoice dues to us.',
        trasnferFundsTab: {
            title: 'Transfer Salary Funds',
            key: 'TSL',
            data: [...bankDetailsCommonData, ...transferSalaryFunds]
        },
        payInvoicesTab: {
            title: 'Pay Invoices',
            key: 'PI',
            data: [...bankDetailsCommonData, ...payInvoices],
        }
    }
})
