import Action from '../action.js';
import Axios from 'axios';
import { APP_VARIABLES, DOMAIN_URL } from '@constants/app-constants';
import { put, takeEvery, call, takeLatest } from 'redux-saga/effects';
import { templateDownload } from '@helpers';

export function* employeePayrollUploadService(action) {
	yield put(Action.creators.employeePayrollUploadLoading(true));
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	let formData = new FormData();
	formData.append('file', action.payload);
	try {
		const uri = `${DOMAIN_URL}/Payroll/${corporateId}/upload-payroll-hr?isOnBoarding=${action?.onBoardingStatus}`;
		const res = yield call(Axios.post, uri, formData);
		yield put(Action.creators.employeePayrollUploadLoading(false));
		yield put(Action.creators.employeePayrollUploadSuccess(res.data));
	} catch (err) {
		yield put(Action.creators.employeePayrollUploadFail(err));
		yield put(Action.creators.employeePayrollUploadLoading(false));
	}
}
export function* downloadTemplateService(action) {
	yield put(Action.creators.putPayrollDownloadTemplateLoading(true));
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	try {
		const uri = `${DOMAIN_URL}/Employees/${corporateId}/data/downloadpayrolldetails?isOnBoarding=${action.payload}`;
		const response = yield call(Axios.get, uri, {
			responseType: 'blob',
			headers: {
				Accept: 'application/pdf',
			},
		});
		yield put(Action.creators.getPayrollDownloadTemplateData(response.data));
		yield put(Action.creators.putPayrollDownloadTemplateLoading(false));
		templateDownload(response.data, 'payrollTemplate.xls');
	} catch (error) {
		yield put(Action.creators.getPayrollDownloadTemplateError(error));
		yield put(Action.creators.putPayrollDownloadTemplateLoading(false));
	}
}
export default function* employeePayrollWatcher() {
	yield takeEvery(Action.types.EMPLOYEE_PAYROLL_UPLOAD, employeePayrollUploadService);
	yield takeLatest(Action.types.GET_PAYROLL_DOWNLOAD_TEMPLATE, downloadTemplateService);
}
